import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CaseSaveDialog from "../components/dialogs/CaseSaveDialog";
import PageHeader from "../components/pages/PageHeader";
import FormWrapper from "../forms/FormWrapper";
import { CaseUiData } from "../forms/schema/CaseUiSchema";
import { SITE_TITLE } from "../helpers/strings";
import { selectLimsOptions } from "../store/metadataSlice";

const NewCase = (): JSX.Element => {
  // Local state
  const [formKey, setFormKey] = useState<number>(0);
  const [formData, setFormData] = useState<CaseUiData>();
  const [showCaseSaveDialog, setShowCaseSaveDialog] = useState<boolean>(false);

  // Redux
  const limsOptions = useSelector(selectLimsOptions);

  // Page title
  const pageTitle = "New case";
  useEffect(() => {
    document.title = SITE_TITLE;
  }, []);

  // Make the form values available to the CaseSaveDialog component
  const handleSubmit = (form: CaseUiData) => {
    setFormData(form);
    setShowCaseSaveDialog(true);
  };

  // Force CaseForm to re-render, resetting all of its form fields
  const resetForm = (): void => {
    setFormKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <div className="container is-max-desktop">
        <PageHeader
          title={pageTitle}
          subtitle="Book in a Reflux or Barrett’s surveillance request form"
        />
        <FormWrapper
          key={formKey}
          formMode="create"
          formLocked={false}
          limsOptions={limsOptions}
          onSubmit={handleSubmit}
        />
      </div>
      {formData && showCaseSaveDialog && (
        <CaseSaveDialog
          formMode="create"
          formData={formData}
          labNumber={formData.labNumber}
          updatePage={resetForm}
          closeCaseDialog={() => setShowCaseSaveDialog(false)}
        />
      )}
    </>
  );
};

export default NewCase;
