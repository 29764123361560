import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CURRENT_YEAR } from "../../../helpers/strings";
import { CaseUiData } from "../../schema/CaseUiSchema";

const LabNumber = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <FormInput
      id="labNumber"
      label="Lab (CYT) number"
      maxLength={10}
      className="is-medium is-uppercase"
      icon={faBarcode}
      placeholder={`${CURRENT_YEAR}CYT00001`}
      error={formState.errors.labNumber}
      {...register("labNumber", {
        setValueAs: (value: string) => value.toUpperCase(),
      })}
    />
  );
};

export default LabNumber;
