import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CURRENT_YEAR } from "../../../helpers/strings";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer } from "../../schema/FormAnswers";

const DeviceIdForFailedAttempt = (): JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { numberOfFailedAttempts } = watch();

  return (
    <FormInput
      addMargin
      id="deviceIdForFailedAttempt"
      label="Cell collection device ID for failed attempt"
      disabled={numberOfFailedAttempts !== Answer.ONE}
      icon={faQrcode}
      placeholder={`(10)${CURRENT_YEAR}000001`}
      help="Optional (EndoSign only)"
      error={formState.errors.deviceIdForFailedAttempt}
      {...register("deviceIdForFailedAttempt")}
    />
  );
};

export default DeviceIdForFailedAttempt;
