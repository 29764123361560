import { SnackbarKey, useSnackbar } from "notistack";
import React from "react";

interface DismissSnackbarButtonProps {
  snackbarKey: SnackbarKey;
}

const DismissSnackbarButton = ({ snackbarKey }: DismissSnackbarButtonProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <button className="delete mr-2" onClick={() => closeSnackbar(snackbarKey)}></button>
  );
};

export default DismissSnackbarButton;
