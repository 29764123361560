import React from "react";
import { useFormContext } from "react-hook-form";

import WarningMessage from "../../../components/atoms/WarningMessage";
import FormDateInput from "../../../components/forms/FormDateInput";
import { isDateMoreThanOneMonthAgo } from "../../../helpers/validation";
import { FormMode } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

export const TEST_ID_FORM_DATE_RECEIVED_WARNING = "FormDateReceivedWarning";

interface ReceiptDateProps {
  formMode: FormMode;
}

const ReceiptDate = ({ formMode }: ReceiptDateProps): JSX.Element => {
  const { control, formState, watch } = useFormContext<CaseUiData>();
  const { dateReceived } = watch();

  // TODO PLAT-5689: Refactor this...
  // Show date warning when receipt date may be wrong at booking in
  const dateReceivedWarning: string | false =
    formMode === "create" &&
    isDateMoreThanOneMonthAgo(dateReceived) &&
    "This was over a month ago";

  return (
    <div className="mb-5">
      <FormDateInput
        todayButton
        id="dateReceived"
        label="Receipt date"
        control={control}
        error={formState.errors.dateReceived}
      />
      {!!dateReceivedWarning && (
        <WarningMessage testId={TEST_ID_FORM_DATE_RECEIVED_WARNING}>
          {dateReceivedWarning}
        </WarningMessage>
      )}
    </div>
  );
};

export default ReceiptDate;
