import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const NumberOfFailedAttempts = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="numberOfFailedAttempts"
      label="Number of failed attempts"
      // Legacy forms offered option to select "2" failed attempts
      options={[...CaseSchemaOptions.numberOfFailedAttempts, Answer.TWO]}
      error={formState.errors.numberOfFailedAttempts}
      {...register("numberOfFailedAttempts")}
    />
  );
};

export default NumberOfFailedAttempts;
