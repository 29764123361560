import * as yup from "yup";

import { UserAccountOperation, UserMfaStatus, UserStatus } from "./UserSchema";

export interface PortalUser extends PortalUserForm {
  userId: string;
  status: UserStatus;
  mfaStatus: UserMfaStatus;
  lastActive: string | null;
  operations: UserAccountOperation[];
}

export type PortalUserGroup = yup.InferType<typeof PortalUserGroupSchema>;
export type PortalUserForm = yup.InferType<typeof PortalUserSchema>;

export const PortalUserGroupSchema = yup.object({
  groupId: yup.string().required(),
  groupName: yup.string(),
  emailNotifications: yup.boolean().required(),
});

export const PortalUserSchema = yup
  .object({
    email: yup.string().email("Must be a valid email address").trim().required(),
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    groups: yup.array().of(PortalUserGroupSchema).required(),
  })
  .required();
