import {
  IconDefinition,
  faCircleChevronRight,
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { format } from "date-fns";
import React from "react";

import { CaseUiData } from "../../forms/schema/CaseUiSchema";
import { CaseInfo, CaseState, PublishedReport } from "../../schemas/ApiSchema";
import LimsCaseLink from "../common/LimsCaseLink";

export const TEST_ID_CASE_TIMELINE_PROCEDURE_COMPLETED = "CaseTimelineProcedureCompleted";
export const TEST_ID_CASE_TIMELINE_SAMPLE_RECEIVED = "CaseTimelineSampleReceived";
export const TEST_ID_CASE_TIMELINE_CASE_BOOKED_IN = "CaseTimelineCaseBookedIn";
export const TEST_ID_CASE_TIMELINE_LIMS_CASE_LINK = "CaseTimelineLimsCaseLink";
export const TEST_ID_CASE_TIMELINE_REPORT_ISSUE_DATE = "CaseTimelineReportIssueDate";
export const TEST_ID_CASE_TIMELINE_CASE_UNLOCKED = "CaseTimelineCaseUnlocked";

interface CaseTimelineProps {
  caseData: CaseUiData;
  caseInfo: CaseInfo;
}

interface CaseTimelineItemProps {
  date: Date | string;
  label: string;
  icon?: IconDefinition;
  dashed?: boolean;
  testId: string;
  children?: React.ReactNode;
}

const isCaseLocked = (caseState: CaseState): boolean => {
  return caseState === CaseState.LOCKED || caseState === CaseState.DELEGATED_TO_LIMS;
};

const CaseTimelineItem = ({
  date,
  label,
  icon = faCircleChevronRight,
  dashed = false,
  testId,
  children,
}: CaseTimelineItemProps): JSX.Element => {
  return (
    <div
      className={classNames("timeline-item", { "timeline-dashed": dashed })}
      data-testid={testId}
    >
      <div className="timeline-marker is-icon">
        <FontAwesomeIcon icon={icon} size="lg" />
      </div>
      <div className="timeline-content">
        <p className="heading">{format(date, "d MMMM yyyy")}</p>
        <p>{label}</p>
        {children}
      </div>
    </div>
  );
};

const getReportLabel = (reasonForAmendment: string | null, index: number): string => {
  if (index === 0) {
    return "Report authorised"; // Original report authorised by pathologist
  } else if (reasonForAmendment !== null) {
    return "Report amendment authorised"; // Clinical amendment authorised by pathologist
  } else {
    return "Report reissued"; // Case locked and report reissued by lab admin team
  }
};

const CaseTimeline = ({
  caseData: { procedureDate, dateReceived, practitionerName },
  caseInfo: { caseState, caseCreated, limsCaseId, publishedReports },
}: CaseTimelineProps): JSX.Element => {
  // Clone and reverse for chronological order
  const reports: PublishedReport[] = [...publishedReports].reverse();

  return (
    <section>
      <h3 className="title is-5">History</h3>
      <div className="timeline">
        <CaseTimelineItem
          date={procedureDate}
          label={`Procedure completed${practitionerName ? ` by ${practitionerName}` : ""}`}
          testId={TEST_ID_CASE_TIMELINE_PROCEDURE_COMPLETED}
        />
        <CaseTimelineItem
          date={dateReceived}
          label="Sample received"
          testId={TEST_ID_CASE_TIMELINE_SAMPLE_RECEIVED}
        />
        <CaseTimelineItem
          date={caseCreated}
          label="Case booked in"
          testId={TEST_ID_CASE_TIMELINE_CASE_BOOKED_IN}
        >
          <LimsCaseLink limsCaseId={limsCaseId} className="is-size-7" />
        </CaseTimelineItem>
        {reports.map(({ versionId, publicationTimestamp, reasonForAmendment }, index) => (
          <CaseTimelineItem
            key={versionId}
            date={publicationTimestamp}
            label={getReportLabel(reasonForAmendment, index)}
            icon={faLock}
            dashed
            testId={TEST_ID_CASE_TIMELINE_REPORT_ISSUE_DATE + versionId}
          />
        ))}
        {publishedReports.length > 0 && !isCaseLocked(caseState) && (
          <div
            className="timeline-header timeline-dashed"
            data-testid={TEST_ID_CASE_TIMELINE_CASE_UNLOCKED}
          >
            <div className="timeline-marker is-icon">
              <FontAwesomeIcon icon={faUnlock} size="lg" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CaseTimeline;
