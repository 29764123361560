import React from "react";
import { useFormContext } from "react-hook-form";

import FormSelect, { FormSelectOption } from "../../../components/forms/FormSelect";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface LimsConsultantProps {
  consultants: FormSelectOption[];
}

const LimsConsultant = ({ consultants }: LimsConsultantProps): JSX.Element => {
  const { control } = useFormContext<CaseUiData>();

  return (
    <FormSelect
      id="consultant"
      label="LIMS consultant"
      disabled
      control={control}
      options={consultants}
      placeholder=""
      help="⚠ Controlled by reporting pathologist"
    />
  );
};

export default LimsConsultant;
