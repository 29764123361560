import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import caseListReducer from "./caseListSlice";
import metadataReducer from "./metadataSlice";
import userListReducer from "./userListSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    metadata: metadataReducer,
    caseList: caseListReducer,
    userList: userListReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
