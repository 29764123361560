import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { RequestFormProps } from "../FormComponent";
import { setValueConfig } from "../FormWrapper";
import {
  BarrettsDetails,
  DeviceDetails,
  LabUseOnly,
  PatientDetails,
  ReportRecipient,
} from "../blocks";
import { ProcedureDetails } from "../blocks/deprecated";
import { HasAdditionalClinicalInformation } from "../fields/clinical";
import { CaseUiData } from "../schema/CaseUiSchema";
import { Answer } from "../schema/FormAnswers";

const TRF_2_Version_2 = ({
  formMode,
  formLocked,
  limsOptions: { specimens, clinicians, caseOrigins, consultants },
}: RequestFormProps): JSX.Element => {
  const { unregister, getValues, setValue } = useFormContext<CaseUiData>();

  useEffect(() => {
    // Discard any fields in form state which aren't used in this form
    unregister([
      "confidentSpongeReachedStomach",
      "hasRefluxSymptoms",
      "hasEoEDiagnosis",
      "deviceIdForFailedAttempt",
      "reasonsForFailedAttempt",
    ]);

    updateNumberOfFailedAttempts();
  }, []);

  // We want to unset the value of numberOfFailedAttempts when switching between request forms to prevent
  // "Was there a failed attempt? Yes" from being mapped to "Number of failed attempts: 1" when switching
  // from a TRF-3 request form to a legacy request form.
  const updateNumberOfFailedAttempts = (): void => {
    if (getValues("numberOfFailedAttempts") === Answer.ONE) {
      setValue("numberOfFailedAttempts", "", setValueConfig);
    }
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <DeviceDetails
            allowUseRecordNumberForPatientName
            disabled={formLocked}
            specimens={specimens}
          />
        </div>
        <div className="tile is-parent">
          <ReportRecipient
            allowUseClinicianForProcedureSite
            disabled={formLocked}
            clinicians={clinicians}
            caseOrigins={caseOrigins}
          />
        </div>
      </div>

      <PatientDetails disabled={formLocked} />

      <Fieldset title="Clinical information" disabled={formLocked}>
        <BarrettsDetails disabled={formLocked} />
        <HasAdditionalClinicalInformation />
      </Fieldset>

      <ProcedureDetails formMode={formMode} formLocked={formLocked} />

      <LabUseOnly
        forceP53
        formMode={formMode}
        disabled={formLocked}
        consultants={consultants}
      />
    </>
  );
};

export default TRF_2_Version_2;
