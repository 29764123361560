import React from "react";
import { useFormContext } from "react-hook-form";

import CheckboxGroup from "../../../components/forms/CheckboxGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const ReasonsForFailedAttempt = (): JSX.Element => {
  const { watch, register } = useFormContext<CaseUiData>();
  const { numberOfFailedAttempts } = watch();

  return (
    <CheckboxGroup
      id="reasonsForFailedAttempt"
      disabled={numberOfFailedAttempts !== Answer.ONE}
      label="Reason for failure"
      options={CaseSchemaOptions.reasonsForFailedAttempt}
      {...register("reasonsForFailedAttempt")}
    />
  );
};

export default ReasonsForFailedAttempt;
