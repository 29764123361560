import classNames from "classnames";
import React from "react";
import { FieldError } from "react-hook-form";

interface RadioGroupProps {
  id: string;
  label: string;
  options: string[];
  indented?: boolean;
  disabled?: boolean;
  error?: FieldError;
  getOptionLabel?: (option: string) => string;
}

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ id, label, options, indented, disabled, error, getOptionLabel, ...props }, ref) => {
    return (
      <div
        role="radiogroup"
        aria-labelledby={`${id}Label`}
        className={classNames("field mb-5", { indented, disabled })}
      >
        <h4 id={`${id}Label`} className="label is-size-6 has-text-weight-bold">
          {label}
        </h4>
        <div className="control">
          {options.map((option) => {
            return (
              <label
                key={option}
                className={classNames("radio", { "has-text-grey-light": disabled })}
              >
                <input
                  type="radio"
                  value={option}
                  disabled={disabled}
                  ref={ref}
                  {...props}
                />
                {getOptionLabel ? getOptionLabel(option) : option}
              </label>
            );
          })}
        </div>
        {error && (
          <p className="help has-text-danger" id={`${id}Error`}>
            {error.message}
          </p>
        )}
      </div>
    );
  }
);

RadioGroup.displayName = "RadioGroup";

export default RadioGroup;
