import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const PatientSex = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="patientSex"
      label="Sex"
      options={CaseSchemaOptions.patientSex}
      error={formState.errors.patientSex}
      {...register("patientSex")}
    />
  );
};

export default PatientSex;
