import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export type ListType = "case" | "user" | "group";

export interface ListCountHeadingProps {
  noun: ListType;
  count: number;
  isLoading?: boolean;
  testId?: string;
}

const ListCountHeading = ({
  noun,
  count,
  isLoading,
  testId,
}: ListCountHeadingProps): JSX.Element => {
  const getHeadingText = (): string => {
    if (isLoading && count === 0) {
      // Initial page load or recovering from no results
      return "";
    } else if (count === 0) {
      return `No ${noun}s found`;
    } else if (count === 1) {
      return `1 ${noun}`;
    } else {
      return `${count} ${noun}s`;
    }
  };

  const headingText = getHeadingText();

  return (
    <h3 className="title is-4" style={{ whiteSpace: "nowrap" }} data-testid={testId}>
      {!!headingText && <span className="mr-2">{headingText}</span>}
      {isLoading && <FontAwesomeIcon spin icon={faSpinner} size="xs" />}
    </h3>
  );
};

export default ListCountHeading;
