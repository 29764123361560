import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CURRENT_YEAR } from "../../../helpers/strings";
import { CaseUiData } from "../../schema/CaseUiSchema";

const DeviceId = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <FormInput
      addMargin
      id="deviceId"
      label="Cell collection device ID"
      icon={faQrcode}
      placeholder={`(10)${CURRENT_YEAR}000001`}
      help="Optional (EndoSign only)"
      error={formState.errors.deviceId}
      {...register("deviceId")}
    />
  );
};

export default DeviceId;
