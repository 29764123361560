import { format } from "date-fns";
import { chain } from "lodash";
import * as yup from "yup";

import { FormSelectOption } from "../components/forms/FormSelect";

export interface UserGroup {
  userGroupId: string;
  userGroupName: string;
  userGroupEmail: string | null;
  activationDate: string | null;
  mfaPolicy: UserGroupMfaPolicy;
  procurementRoutes: ProcurementRoute[];
}

export enum UserGroupMfaPolicy {
  REQUIRED = "Required",
  OPTIONAL = "Optional",
}

export interface ProcurementRoute {
  procurementRouteId: string; // Not used yet
  procurementRouteLabel: string; // Not used yet
  limsClinicianId: string;
  limsCaseOriginId: string;
}

export type UserGroupForm = yup.InferType<typeof UserGroupSchema>;
export type UserGroupFormData = Omit<UserGroupForm, "activationDate"> & {
  activationDate: string | null;
};

export const UserGroupSchema = yup
  .object({
    userGroupName: yup
      .string()
      .trim()
      .required()
      .test(
        "does-not-contain-CSD",
        'Must not contain "CSD"',
        (value) => !value.includes("CSD")
      ),
    limsClinicianId: yup.string().required(),
    limsCaseOriginId: yup.string().required(),
    activationDate: yup
      .date()
      .optional()
      .nullable()
      .transform((date) => date ?? null),
    userGroupEmail: yup
      .string()
      .email("Must be a valid email address")
      .trim()
      .optional()
      .nullable()
      .transform((value) => value || null),
    mfaRequired: yup.boolean().required(),
  })
  .required();

export const serializeUserGroup = (form: UserGroupForm): UserGroupFormData => {
  return {
    ...form,
    activationDate: form.activationDate
      ? format(form.activationDate, "yyyy-MM-dd")
      : null,
  };
};

export const deserializeUserGroup = (group?: UserGroup): UserGroupForm | undefined => {
  if (!group) return;
  const { userGroupName, activationDate, userGroupEmail, mfaPolicy } = group;
  const [{ limsClinicianId, limsCaseOriginId }] = group.procurementRoutes;
  return {
    userGroupName,
    limsClinicianId,
    limsCaseOriginId,
    activationDate: activationDate ? new Date(activationDate) : null,
    userGroupEmail,
    mfaRequired: mfaPolicy === UserGroupMfaPolicy.REQUIRED ? true : false,
  };
};

export const mapUserGroupsToListOptions = (
  userGroups: UserGroup[]
): FormSelectOption[] => {
  return (
    chain(userGroups)
      // Extract only the label and value properties required for a dropdown list
      .map(({ userGroupName, userGroupId }) => ({
        label: userGroupName,
        value: userGroupId,
      }))
      // Sort user groups alphabetically by name
      .sortBy("label")
      .value()
  );
};
