import { faCircle, faLock, faPen } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { isFuture } from "date-fns";
import React, { MouseEvent } from "react";
import { useSelector } from "react-redux";

import { CHECK_LIMS } from "../../helpers/strings";
import { UserGroup, UserGroupMfaPolicy } from "../../schemas/UserGroupSchema";
import { selectLimsOptionLabel } from "../../store/metadataSlice";
import LabelledIcon from "../atoms/LabelledIcon";
import MissingFieldTag from "../atoms/MissingFieldTag";

export const TEST_ID_EDIT_USER_GROUP_BUTTON = "EditUserGroupButton";

interface UserGroupRowProps {
  userGroup: UserGroup;
  openUserGroupDialog: (e?: MouseEvent, id?: string) => void;
}

const ActivationDate = ({ date }: { date: string | null }): JSX.Element | string => {
  if (!date) return "-";

  const displayDate: string = format(date, "d MMM yyyy");
  if (isFuture(date)) {
    return `Scheduled for ${displayDate}`;
  } else {
    return <LabelledIcon icon={faCircle} label={displayDate} color="success" />;
  }
};

const MfaPolicy = ({ policy }: { policy: UserGroupMfaPolicy }): JSX.Element | string => {
  if (policy === UserGroupMfaPolicy.REQUIRED) {
    return <LabelledIcon icon={faLock} label={policy} color="success" />;
  } else {
    return policy;
  }
};

const UserGroupRow = ({
  userGroup: {
    userGroupId,
    userGroupName,
    userGroupEmail,
    activationDate,
    mfaPolicy,
    procurementRoutes,
  },
  openUserGroupDialog,
}: UserGroupRowProps): JSX.Element => {
  const [{ limsClinicianId, limsCaseOriginId }] = procurementRoutes;

  // Redux
  const clinician = useSelector(selectLimsOptionLabel("clinicians", limsClinicianId));
  const caseOrigin = useSelector(selectLimsOptionLabel("caseOrigins", limsCaseOriginId));

  return (
    <tr>
      <td>
        <div className="cy-user-row__name">{userGroupName}</div>
        <div className="cy-user-row__email">{userGroupEmail}</div>
      </td>
      <td>
        <div className="cy-user-row__group">
          {clinician === CHECK_LIMS ? (
            <MissingFieldTag message="No matching clinician" />
          ) : (
            clinician
          )}
        </div>
        <div>
          {caseOrigin === CHECK_LIMS ? (
            <MissingFieldTag message="No matching case origin" />
          ) : (
            caseOrigin
          )}
        </div>
      </td>
      <td>
        <ActivationDate date={activationDate} />
      </td>
      <td>
        <MfaPolicy policy={mfaPolicy} />
      </td>
      <td>
        <a
          href=""
          role="button"
          className="is-block mb-1"
          data-testid={TEST_ID_EDIT_USER_GROUP_BUTTON}
          onClick={(e) => openUserGroupDialog(e, userGroupId)}
        >
          <LabelledIcon icon={faPen} label="Edit" />
        </a>
      </td>
    </tr>
  );
};

export default UserGroupRow;
