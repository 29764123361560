import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const DidPatientSwallow = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="didPatientSwallow"
      label="Do you think the patient swallowed the capsule so it reached their stomach?"
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.didPatientSwallow}
      {...register("didPatientSwallow")}
    />
  );
};

export default DidPatientSwallow;
