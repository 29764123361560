import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isEmpty, mapValues, pickBy, trim } from "lodash";

import { RootState } from ".";
import { CaseUiData } from "../forms/schema/CaseUiSchema";
import { CaseListResult, GetCasesResponse } from "../schemas/ApiSchema";
import { CustomError, dataService } from "../services/data.service";

export type SearchParameters = {
  [K in keyof CaseUiData]?: string;
};

export interface CaseListState {
  status: "pending" | "succeeded" | "failed";
  error?: CustomError;
  results: CaseListResult[];
  total: number;
  searchParameters: SearchParameters;
  totalPages: number;
  page: number;
}

const initialState: CaseListState = {
  status: "pending",
  error: undefined,
  results: [],
  total: 0,
  searchParameters: {
    // Keep in sync with the fields in CaseSearch.tsx
    labNumber: "",
    recordNumber: "",
    patientFirstName: "",
    patientSurname: "",
    patientIdentifier: "",
    userGroupId: "",
  },
  totalPages: 1,
  page: 1,
};

export const fetchCases = createAsyncThunk<
  GetCasesResponse,
  void,
  {
    rejectValue: CustomError;
    state: RootState;
  }
>("search/fetchCases", async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const response = await dataService.getCases(
    state.caseList.page,
    selectSearchParameters(state)
  );
  return response.data ?? rejectWithValue(response.error);
});

export const caseListSlice = createSlice({
  name: "caseList",
  initialState,
  reducers: {
    updateSearchParameter: (
      state,
      action: PayloadAction<{ key: keyof SearchParameters; value?: string }>
    ) => {
      const { key, value } = action.payload;
      state.page = 1;
      state.searchParameters[key] = value ?? "";
    },
    removeSearchParameter: (state, action: PayloadAction<keyof SearchParameters>) => {
      state.page = 1;
      state.searchParameters[action.payload] = "";
    },
    updatePageNumber: (state, action: PayloadAction<number>) => {
      if (action.payload > 0 && action.payload <= state.totalPages) {
        state.page = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCases.pending, (state) => {
        state.error = undefined;
        state.status = "pending";
      })
      .addCase(fetchCases.fulfilled, (state, action: PayloadAction<GetCasesResponse>) => {
        const { cases, totalCases, totalPages, page } = action.payload;
        state.status = "succeeded";
        state.results = cases;
        state.total = totalCases;
        state.totalPages = totalPages;
        state.page = page;
      })
      .addCase(fetchCases.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Actions
export const { updateSearchParameter, removeSearchParameter, updatePageNumber } =
  caseListSlice.actions;

// Selector function to trim and filter empty strings from search parameters
export const selectSearchParameters = createSelector(
  (state: RootState) => state.caseList.searchParameters,
  (searchParameters): SearchParameters =>
    pickBy(mapValues(searchParameters, trim), (value) => !isEmpty(value))
);

export default caseListSlice.reducer;
