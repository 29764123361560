import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const MFAComplete = (): JSX.Element => {
  return (
    <div className="content">
      <p>
        Two-factor authentication is enabled for your account.
        <FontAwesomeIcon icon={faCheck} color="green" className="ml-2" />
      </p>
      <p>
        Contact an administrator if you need to disable or reset two-factor
        authentication.
      </p>
    </div>
  );
};

export default MFAComplete;
