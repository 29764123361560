import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const IsBloodOnSponge = (): JSX.Element => {
  const { register, formState, setValue } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="isBloodOnSponge"
      label="Is there blood on the sponge?"
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.isBloodOnSponge}
      {...register("isBloodOnSponge", {
        // Reset follow-up question
        onChange: () => setValue("takesAnticoagulants", null, setValueConfig),
      })}
    />
  );
};

export default IsBloodOnSponge;
