import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const IsDebrisOnSponge = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="isDebrisOnSponge"
      label="Is there debris on the sponge?"
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.isDebrisOnSponge}
      {...register("isDebrisOnSponge")}
    />
  );
};

export default IsDebrisOnSponge;
