import React from "react";
import { useSelector } from "react-redux";

import { CHECK_LIMS } from "../../helpers/strings";
import { PathologistUser } from "../../schemas/PathologistSchema";
import { selectLimsOptionLabel, selectPathologists } from "../../store/metadataSlice";
import MissingFieldTag from "../atoms/MissingFieldTag";
import UserMfaStatusIndicator from "./UserMfaStatusIndicator";
import UserStatusIndicator from "./UserStatusIndicator";

export const TEST_ID_EDIT_USER_BUTTON = "EditUserButton";
export const TEST_ID_MANAGE_USER_BUTTON = "ManageUserButton";

interface PathologistRowProps {
  user: PathologistUser;
}

const PathologistRow = ({
  user: { userId, firstName, lastName, email, userName, knownAs, status, mfaStatus },
}: PathologistRowProps): JSX.Element => {
  const pathologist = useSelector(selectPathologists).find(
    ({ pathkitUserId }) => pathkitUserId === userId
  );
  const limsConsultantName = useSelector(
    selectLimsOptionLabel("consultants", pathologist?.limsConsultantId)
  );

  return (
    <tr>
      <td>
        <div className="cy-user-row__name">
          {firstName ?? <MissingFieldTag message="No first name" />}{" "}
          {lastName ?? <MissingFieldTag message="No last name" />}
        </div>
        <div className="cy-user-row__email">{userName}</div>
      </td>
      <td>
        {limsConsultantName === CHECK_LIMS ? (
          <MissingFieldTag message="No matching consultant" />
        ) : (
          limsConsultantName
        )}
      </td>
      <td>{knownAs}</td>
      <td>{email}</td>
      <td className="cy-user-row__status">
        <UserStatusIndicator status={status} />
        <UserMfaStatusIndicator mfaStatus={mfaStatus} />
      </td>
    </tr>
  );
};

export default PathologistRow;
