import React from "react";

import { LimsOptions } from "../store/metadataSlice";
import { FormMode } from "./FormWrapper";
import {
  TRF_1_Version_2,
  TRF_2_Version_2,
  TRF_3_Version_1,
  TRF_4_Version_1,
} from "./layouts";
import { CaseUiData } from "./schema/CaseUiSchema";
import { RequestFormType } from "./schema/RequestForms";

export interface RequestFormProps {
  formMode: FormMode;
  formLocked: boolean;
  formValues?: CaseUiData;
  limsOptions: LimsOptions;
  onSubmit: (form: CaseUiData) => void;
}

const EmptyComponent: React.FC = () => <></>;

export const getRequestFormComponent = (formType?: RequestFormType) => {
  switch (formType) {
    case RequestFormType.TRF_1_V2:
      return { FormComponent: TRF_1_Version_2 };
    case RequestFormType.TRF_2_V2:
      return { FormComponent: TRF_2_Version_2 };
    case RequestFormType.TRF_3_V1:
      return { FormComponent: TRF_3_Version_1 };
    case RequestFormType.TRF_4_V1:
      return { FormComponent: TRF_4_Version_1 };
    default:
      return { FormComponent: EmptyComponent };
  }
};
