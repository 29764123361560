import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { addMonths, isBefore, startOfTomorrow } from "date-fns";
import { isNull } from "lodash";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { UserGroupFormMode } from "../../routes/UserGroups";
import {
  UserGroup,
  UserGroupForm,
  UserGroupSchema,
  deserializeUserGroup,
  serializeUserGroup,
} from "../../schemas/UserGroupSchema";
import { dataService } from "../../services/data.service";
import { selectLimsOptions } from "../../store/metadataSlice";
import FormCheckbox from "../forms/FormCheckbox";
import FormDateInput from "../forms/FormDateInput";
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";

export const TEST_ID_DIALOG_USER_GROUP_TITLE = "DialogUserGroupTitle";
export const TEST_ID_DIALOG_SAVE_USER_GROUP_BUTTON = "DialogSaveUserGroupButton";
export const TEST_ID_DIALOG_CANCEL_BUTTON = "DialogCancelButton";

interface UserGroupFormDialogProps {
  selectedUserGroup?: UserGroup;
  closeUserGroupDialog: () => void;
}

const UserGroupFormDialog = ({
  selectedUserGroup,
  closeUserGroupDialog,
}: UserGroupFormDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  // Redux
  const { clinicians, caseOrigins } = useSelector(selectLimsOptions);

  // Destructure existing user group if provided, or default to empty values
  // for a new user group
  const {
    userGroupName = "",
    limsClinicianId = "",
    limsCaseOriginId = "",
    activationDate = null,
    userGroupEmail = null,
    mfaRequired = false,
  } = deserializeUserGroup(selectedUserGroup) || {};

  // Calculate form mode
  const { NEW, EDIT } = UserGroupFormMode;
  const { userGroupId } = selectedUserGroup || {};
  const formMode: UserGroupFormMode = userGroupId ? EDIT : NEW;

  // Activation date boundaries, rules and help text
  const tomorrow: Date = startOfTomorrow();
  const oneMonthFromTomorrow: Date = addMonths(tomorrow, 1);
  const isActivationDateLocked =
    !isNull(activationDate) && isBefore(activationDate, tomorrow);
  const activationDateLockedHelp = `This group was already activated, so the
                                    activation date can no longer be changed.
                                    Results and notifications may have been
                                    issued for cases assigned to this group.`;
  const activationDateUpdateHelp = `When should results for this group begin
                                    appearing in the portal? This will also
                                    determine the start date for individual and
                                    (optional) shared mailbox notifications.`;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserGroupForm>({
    mode: "onSubmit",
    defaultValues: {
      userGroupName,
      limsClinicianId,
      limsCaseOriginId,
      activationDate,
      userGroupEmail,
      mfaRequired,
    },
    resolver: yupResolver(UserGroupSchema),
  });

  const handleClose = () => !busy && closeUserGroupDialog();

  const onSubmit = async (form: UserGroupForm): Promise<void> => {
    setError("");
    setBusy(true);
    const requestBody = serializeUserGroup(form);
    const response =
      formMode === EDIT && userGroupId
        ? await dataService.updateUserGroup(userGroupId, requestBody)
        : await dataService.createUserGroup(requestBody);
    if (response.data) {
      const successMessage = `${formMode === NEW ? "Added" : "Updated"} ${form.userGroupName}`;
      enqueueSnackbar(successMessage, { variant: "success" });
      closeUserGroupDialog();
    } else {
      setError(response.error?.msg ?? "Unexpected error. Please try again later.");
      setBusy(false);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content" style={{ maxWidth: 500, overflow: "visible" }}>
        <div className="box has-background-grey-lighter">
          <h3 className="title is-4" data-testid={TEST_ID_DIALOG_USER_GROUP_TITLE}>
            {formMode}
          </h3>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              addMargin
              autoFocus={formMode === NEW}
              id="userGroupName"
              label="User group name"
              disabled={busy}
              error={errors.userGroupName}
              {...register("userGroupName")}
            />
            <FormSelect
              id="limsClinicianId"
              label="LIMS clinician"
              disabled={busy || formMode === EDIT}
              control={control}
              options={clinicians}
              error={errors.limsClinicianId}
            />
            <FormSelect
              id="limsCaseOriginId"
              label="LIMS case origin"
              disabled={busy || formMode === EDIT}
              control={control}
              options={caseOrigins}
              error={errors.limsCaseOriginId}
            />
            <div className="columns">
              <div className="column is-5 pb-0">
                <FormDateInput
                  id="activationDate"
                  label="Portal activation date"
                  disabled={busy || isActivationDateLocked}
                  minDate={tomorrow}
                  maxDate={oneMonthFromTomorrow}
                  control={control}
                  error={errors.activationDate}
                />
              </div>
              <div className="column is-7 help">
                {isActivationDateLocked
                  ? activationDateLockedHelp
                  : activationDateUpdateHelp}
              </div>
            </div>
            <FormInput
              addMargin
              id="userGroupEmail"
              label="(Optional) Shared mailbox email address"
              disabled={busy}
              error={errors.userGroupEmail}
              {...register("userGroupEmail")}
            />
            <FormCheckbox
              addMargin
              id="mfaRequired"
              heading="Portal security policy"
              label="Require users to enable multi-factor authentication"
              disabled={busy}
              {...register("mfaRequired")}
            />
            <div className="buttons">
              <button
                type="submit"
                className={classNames("button is-primary", { "is-loading": busy })}
                data-testid={TEST_ID_DIALOG_SAVE_USER_GROUP_BUTTON}
              >
                {formMode === NEW ? "Create new group" : "Save changes"}
              </button>
              <button
                type="button"
                disabled={busy}
                className="button is-light"
                data-testid={TEST_ID_DIALOG_CANCEL_BUTTON}
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
            {!!error && <p className="notification is-danger">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserGroupFormDialog;
