import { format } from "date-fns";

import { FormSelectOption } from "../components/forms/FormSelect";

export const SITE_TITLE = "LabKit - Cyted Health";

// Placeholder for dropdown list values not found in state.metadata.limsOptions
export const CHECK_LIMS = "[Check LIMS]";

// Current year as two digits for placeholder attributes
export const CURRENT_YEAR = format(new Date(), "yy");

export const getInitialsFromPatientName = (
  firstName: string,
  surname: string
): string => {
  return firstName.trim().charAt(0) + surname.trim().charAt(0);
};

export const getPatientNameFromInitials = (
  initials: string
): { firstName: string; surname: string } => {
  return { firstName: initials.charAt(0), surname: initials.charAt(1) };
};

export const getFormSelectOptionsFromArray = (options: string[]): FormSelectOption[] => {
  return options.map((item) => ({ label: item, value: item }));
};
