import { format } from "date-fns";
import { every, has, isEmpty, isEqual, sortBy } from "lodash";

import { CaseApiData } from "../forms/schema/CaseApiSchema";
import { CaseUiData } from "../forms/schema/CaseUiSchema";
import { AllowedSlides, SlideOptions, SlidesMap } from "../forms/schema/FormAnswers";
import { PathologistUserResults, PortalUserResults } from "../store/userListSlice";
import { PathologistUser } from "./PathologistSchema";
import { PortalUser } from "./PortalUserSchema";

// API responses
export interface GetCaseResponse {
  caseData: CaseApiData;
  caseInfo: CaseInfo;
}

export interface SaveCaseResponse {
  limsCaseId: string;
  caseVersionId: string;
}

export interface GetUsersResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalUsers: number;
  users: PortalUser[] | PathologistUser[];
}

// Type guard: only portal users are assigned to (user) groups
export const isPortalUserResults = (
  response: GetUsersResponse
): response is PortalUserResults => {
  return isEmpty(response.users) || every(response.users, (user) => has(user, "groups"));
};

// Type guard: only pathologists have "known as" alternative names
export const isPathologistUserResults = (
  response: GetUsersResponse
): response is PathologistUserResults => {
  return isEmpty(response.users) || every(response.users, (user) => has(user, "knownAs"));
};

export interface GetCasesResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalCases: number;
  cases: CaseListResult[];
}

export interface CaseListResult {
  caseData: CaseListResultData;
  caseState: CaseState;
  reasonForChange: CaseStateReasonForChange | null;
  permissions: CasePermissions;
}

export type CaseListResultData = {
  userGroupId: string | null;
  labNumber: string;
  dateReceived: string;
  recordNumber: string;
  patientIdentifier: string;
  patientSex: string;
  patientFirstName: string;
  patientSurname: string;
  caseOrigin: string;
  clinician: string;
  consultant: string;
  limsCaseId: string;
};

export interface CaseStateTransitionResponse {
  internalCaseId: string;
  newVersionId: string;
  newState: CaseState;
  allowedStates: CaseState[] | null;
  permissions: CasePermissions;
}

// GET /case does return some extra properties which are not currently used
// by the UI: labKitApiVersion, pathKitApiVersion, micro, publishedReports,
// internalCaseId and versionIssuedAsReport.
export interface CaseInfo {
  caseState: CaseState;
  caseCreated: string;
  caseVersionId: string;
  limsCaseId: number | null;
  permissions: CasePermissions;
  publishedReports: PublishedReport[];
}

export enum CaseState {
  DELEGATED_TO_LIMS = "DelegatedToLims",
  AWAITING_SLIDES = "AwaitingSlides",
  READY_FOR_PATHOLOGIST = "ReadyForPathologist",
  REPORT_SUBMITTED = "ReportSubmitted",
  LOCKED = "Locked",
  WITH_THE_LAB = "WithTheLab",
}

export interface CasePermissions {
  canEditCase: boolean;
  canEditMicro: boolean;
  operations: CaseOperation[];
}

export type CaseTransition = "Lock" | "Unlock";

export interface CaseOperation {
  operation: CaseTransition;
  reasons: CaseStateReasonForChange[];
}

export enum CaseStateReasonForChange {
  LABKIT_MATERIAL_CORRECTION = "LabKitMaterialCorrection",
  LABKIT_NON_MATERIAL_CORRECTION = "LabKitNonMaterialCorrection",
  MICRO_CORRECTION = "MicroCorrection",
}

export interface PublishedReport {
  versionId: string;
  publicationTimestamp: string;
  reasonForAmendment: string | null;
}

// Codecs
export const serializeCase = (form: CaseUiData): CaseApiData => {
  return {
    ...form,
    // Either yyyy or yyyy-MM-dd for date of birth
    patientDateOfBirth: format(
      form.patientDateOfBirth,
      form.patientDateOfBirthIsYearOnly ? "yyyy" : "yyyy-MM-dd"
    ),
    // Always yyyy-MM for last diagnostic endoscopy (if given)
    dateLastEndoscopy: form.dateLastEndoscopy
      ? format(form.dateLastEndoscopy, "yyyy-MM")
      : null,
    // Always yyyy-MM-dd for all other dates
    procedureDate: format(form.procedureDate, "yyyy-MM-dd"),
    dateReceived: format(form.dateReceived, "yyyy-MM-dd"),
    slides: SlidesMap[form.slides as SlideOptions],
  };
};

export const deserializeCase = (caseData: CaseApiData): CaseUiData => {
  const deserializedCase = {
    ...caseData,

    // TODO PLAT-5690: Newer fields which may be null for older/existing cases
    // need to be given default values in order to satisfy CaseUiData. Perhaps
    // there's a way to avoid this repetition and maintenance burden?
    deviceId: caseData.deviceId ?? "",
    userGroupId: caseData.userGroupId ?? "",
    pathologistId: caseData.pathologistId ?? "",
    caseOriginName: caseData.caseOriginName ?? "",
    consultantName: caseData.consultantName ?? "",
    patientIdentifierAlternative: caseData.patientIdentifierAlternative ?? "",
    patientIdentifierNotProvided: caseData.patientIdentifierNotProvided ?? false,
    hasAdditionalClinicalInformation: caseData.hasAdditionalClinicalInformation ?? false,

    // Parse strings into Date objects. The following are all valid arguments
    // to the Date() constructor: `YYYY`, `YYYY-MM`, `YYYY-MM-DD`.
    patientDateOfBirth: new Date(caseData.patientDateOfBirth),
    dateLastEndoscopy: caseData.dateLastEndoscopy
      ? new Date(caseData.dateLastEndoscopy)
      : null,
    procedureDate: new Date(caseData.procedureDate),
    dateReceived: new Date(caseData.dateReceived),

    // Slides need converting from an array to their respective string
    slides: convertSlidesToString(caseData.slides),
  };

  return deserializedCase;
};

// Converts a DB array ["HAndE", "TFF3"] to its UI string, e.g. "H+E and TFF3"
const convertSlidesToString = (slides: AllowedSlides[]): string => {
  for (const [key, array] of Object.entries(SlidesMap)) {
    // Use sortBy to ensure that order of strings returned by backend array consistent with UI
    if (isEqual(sortBy(slides), sortBy(array))) return key;
  }
  // Leave the radio button unchecked if the array of slides from the DB doesn't
  // match an available radio option in the UI.
  return "";
};
