import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface WarningMessageProps {
  children: React.ReactNode;
  testId?: string;
}

const WarningMessage = ({ children, testId }: WarningMessageProps): JSX.Element => {
  return (
    <div className="cy-warning-message" data-testid={testId}>
      <div className="cy-warning-message__icon">
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default WarningMessage;
