import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SortIndicator = (): JSX.Element => {
  return (
    <p className="is-size-7 is-italic">
      <FontAwesomeIcon icon={faArrowDownWideShort} className="mr-1" />
      Most recently booked in
    </p>
  );
};

export default SortIndicator;
