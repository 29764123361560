import { faPlus } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { debounce } from "lodash";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LabelledIcon from "../components/atoms/LabelledIcon";
import ListCountHeading from "../components/atoms/ListCountHeading";
import PortalUserFormDialog from "../components/dialogs/PortalUserFormDialog";
import PortalUserManageDialog from "../components/dialogs/PortalUserManageDialog";
import Box from "../components/forms/Box";
import PageHeader from "../components/pages/PageHeader";
import Pagination, { FOUR_HUNDRED_MILLIS } from "../components/search/Pagination";
import PathologistTable from "../components/users/PathologistTable";
import PortalUserTable from "../components/users/PortalUserTable";
import { SITE_TITLE } from "../helpers/strings";
import { PortalUser } from "../schemas/PortalUserSchema";
import { UserType } from "../schemas/UserSchema";
import { AppDispatch, RootState } from "../store";
import { fetchUsers, updateListType, updatePageNumber } from "../store/userListSlice";
import ErrorPage from "./ErrorPage";

export const TEST_ID_NEW_USER_BUTTON = "NewUserButton";
export const TEST_ID_USER_LIST_TOGGLE = "UserListToggle";
export const TEST_ID_USER_LIST_TABLE = "UserListTable";
export const TEST_ID_USER_LIST_TABLE_HEADING = "UserListTableHeading";

export enum PortalUserDialogType {
  FORM,
  MANAGE,
}

export enum PortalUserFormMode {
  NEW = "New portal user",
  EDIT = "Edit portal user",
}

const UserList = (): JSX.Element => {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [visibleUserDialog, setVisibleUserDialog] = useState<PortalUserDialogType>();

  const { PORTAL, PATHOLOGIST } = UserType;
  const { FORM, MANAGE } = PortalUserDialogType;

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { status, error, listType, results } = useSelector(
    (state: RootState) => state.userList
  );

  // Page title and subtitle
  const pageTitle = "Users";
  const pageSubtitle = `${listType} user accounts`;
  useEffect(() => {
    document.title = `${pageSubtitle} | ${SITE_TITLE}`;
    dispatch(fetchUsers(listType));
  }, [listType]);

  const isError = status === "failed";
  const isLoading = status === "pending";
  const isLoaded = status === "succeeded";
  const portalUsers = results[PORTAL].users;
  const pathologistUsers = results[PATHOLOGIST].users;
  const { page, totalPages, totalUsers } = results[listType];
  const showResults = (isLoading || isLoaded) && totalUsers > 0;

  // Get the portal user object for the selected user ID
  const selectedUser: PortalUser | undefined = portalUsers.find(
    ({ userId }) => userId === selectedUserId
  );

  const debouncedFetchUsers = useCallback(
    debounce((listType: UserType) => dispatch(fetchUsers(listType)), FOUR_HUNDRED_MILLIS),
    []
  );

  const handlePageChange = (page: number): void => {
    dispatch(updatePageNumber({ page, listType }));
    debouncedFetchUsers(listType);
  };

  const openUserDialog = (
    e: MouseEvent,
    dialog: PortalUserDialogType,
    userId?: string
  ): void => {
    e.preventDefault();
    setSelectedUserId(userId);
    setVisibleUserDialog(dialog);
  };

  const closeUserDialog = (e?: MouseEvent): void => {
    e?.preventDefault();
    setSelectedUserId(undefined);
    setVisibleUserDialog(undefined);
    dispatch(fetchUsers(listType));
  };

  const handleUserListToggle = (e: MouseEvent, userType: UserType): void => {
    e.preventDefault();
    if (userType !== listType) {
      dispatch(updateListType(userType));
    }
  };

  const UserListToggle = (): JSX.Element => {
    const userTypes = Object.values(UserType);
    return (
      <div className="tabs is-toggle is-centered">
        <ul>
          {userTypes.map((userType) => {
            const isSelected: boolean = userType === listType;
            return (
              <li
                key={userType}
                className={classNames({ "is-active": isSelected })}
                data-testid={TEST_ID_USER_LIST_TOGGLE + userType}
              >
                <a
                  href=""
                  role="radio"
                  aria-checked={isSelected}
                  onClick={(e) => handleUserListToggle(e, userType)}
                >
                  {userType}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const NewUserButton = (): JSX.Element => {
    return (
      <button
        type="button"
        className={classNames("button is-primary", {
          // Only portal users can be created via the UI
          "is-hidden": listType !== PORTAL,
        })}
        data-testid={TEST_ID_NEW_USER_BUTTON}
        onClick={(e) => openUserDialog(e, FORM)}
      >
        <LabelledIcon icon={faPlus} label="New user" />
      </button>
    );
  };

  if (isError) {
    return <ErrorPage title={error?.error} subtitle={error?.msg} />;
  }

  return (
    <>
      <div className="container">
        <PageHeader title={pageTitle} subtitle={pageSubtitle} />
        <div className="columns is-vcentered is-mobile mb-1">
          <div className="column">
            <ListCountHeading
              noun="user"
              count={totalUsers}
              isLoading={isLoading}
              testId={TEST_ID_USER_LIST_TABLE_HEADING}
            />
          </div>
          <div className="column">
            <UserListToggle />
          </div>
          <div className="column has-text-right">
            <NewUserButton />
          </div>
        </div>
        {showResults && (
          <>
            <Pagination
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <Box className="table-container my-4">
              {listType === PORTAL && (
                <PortalUserTable users={portalUsers} openUserDialog={openUserDialog} />
              )}
              {listType === PATHOLOGIST && <PathologistTable users={pathologistUsers} />}
            </Box>
            <Pagination
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
      {visibleUserDialog === FORM && (
        <PortalUserFormDialog
          selectedUser={selectedUser}
          closeUserDialog={closeUserDialog}
        />
      )}
      {visibleUserDialog === MANAGE && selectedUser && (
        <PortalUserManageDialog
          selectedUser={selectedUser}
          closeUserDialog={closeUserDialog}
        />
      )}
    </>
  );
};

export default UserList;
