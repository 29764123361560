import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, ScrollRestoration } from "react-router-dom";

import LoadingBackdrop from "../components/navigation/LoadingBackdrop";
import Navbar from "../components/navigation/Navbar";
import { AppDispatch, RootState } from "../store";
import { fetchUserData } from "../store/authSlice";
import { fetchBootData } from "../store/metadataSlice";

const Root = (): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.metadata);

  useEffect(() => {
    dispatch(fetchBootData());
    dispatch(fetchUserData());
  }, []);

  if (status === "booting") {
    return <LoadingBackdrop open title="Connecting to LIMS" />;
  }

  if (status === "failed") {
    return (
      <LoadingBackdrop
        open
        error
        title="Connection failed"
        subtitle={error?.msg ?? "Try reloading the page"}
      />
    );
  }

  return (
    <>
      <Navbar />
      <main className="section pt-0">
        <Outlet />
      </main>
      <ScrollRestoration />
    </>
  );
};

export default Root;
