import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { PortalUserFormMode } from "../../routes/UserList";
import {
  PortalUser,
  PortalUserForm,
  PortalUserGroup,
  PortalUserSchema,
} from "../../schemas/PortalUserSchema";
import { dataService } from "../../services/data.service";
import {
  selectAllUserGroupOptions,
  selectValidUserGroupOptions,
} from "../../store/metadataSlice";
import FormCheckbox from "../forms/FormCheckbox";
import FormInput from "../forms/FormInput";
import FormSelect, { FormSelectOption } from "../forms/FormSelect";

export const TEST_ID_DIALOG_PORTAL_USER_TITLE = "DialogPortalUserTitle";
export const TEST_ID_DIALOG_ADD_USER_GROUP_BUTTON = "DialogAddUserGroupButton";
export const TEST_ID_DIALOG_REMOVE_USER_GROUP_BUTTON = "DialogRemoveUserGroupButton";
export const TEST_ID_DIALOG_SAVE_USER_BUTTON = "DialogSaveUserButton";
export const TEST_ID_DIALOG_CANCEL_BUTTON = "DialogCancelButton";

interface PortalUserFormDialogProps {
  selectedUser?: PortalUser;
  closeUserDialog: (e?: MouseEvent) => void;
}

const PortalUserFormDialog = ({
  selectedUser,
  closeUserDialog,
}: PortalUserFormDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  // Redux
  const allUserGroupOptions = useSelector(selectAllUserGroupOptions);
  const validUserGroupOptions = useSelector(selectValidUserGroupOptions);

  // Empty user group row
  const newUserGroupRow: PortalUserGroup = { groupId: "", emailNotifications: false };

  // Destructure existing user if provided, or default to empty values for a new user
  const {
    userId,
    email = "",
    firstName = "",
    lastName = "",
    groups = [{ ...newUserGroupRow }],
  } = selectedUser || {};

  // Calculate form mode
  const { NEW, EDIT } = PortalUserFormMode;
  const formMode: PortalUserFormMode = userId ? EDIT : NEW;

  // Don't offer incorrectly-mapped user groups when creating a user, but do
  // include them when editing an existing user in case any of their assigned
  // user groups have lost their mapping(s) since the user was created.
  const userGroupOptions: Record<PortalUserFormMode, FormSelectOption[]> = {
    [NEW]: validUserGroupOptions,
    [EDIT]: allUserGroupOptions,
  };

  // Populate the form with an existing portal user (including an empty user group
  // control if they don't yet belong to any groups), otherwise initialise an empty
  // form to create a new portal user.
  const defaultValues: PortalUserForm = {
    email,
    firstName,
    lastName,
    groups: groups.length === 0 ? [{ ...newUserGroupRow }] : groups,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PortalUserForm>({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(PortalUserSchema),
  });

  // Users may belong to multiple user groups
  const { fields, append, remove } = useFieldArray({ name: "groups", control });

  const handleClose = () => !busy && closeUserDialog();

  const getSuccessMessage = ({ email, firstName, lastName }: PortalUserForm): string => {
    switch (formMode) {
      case NEW:
        return `New account email sent to ${email}`;
      case EDIT:
        return `Updated account for ${firstName} ${lastName}`;
    }
  };

  const getSubmitRequestBody = (form: PortalUserForm): PortalUserForm => {
    return {
      ...form,
      groups: form.groups.map((group) => omit(group, "groupName")),
    };
  };

  const onSubmit = async (form: PortalUserForm): Promise<void> => {
    setError("");
    setBusy(true);
    const requestBody = getSubmitRequestBody(form);
    const response =
      formMode === EDIT && userId
        ? await dataService.updatePortalUser(userId, requestBody)
        : await dataService.createPortalUser(requestBody);
    if (response.data) {
      const successMessage = getSuccessMessage(form);
      enqueueSnackbar(successMessage, { variant: "success" });
      closeUserDialog();
    } else {
      setError(response.error?.msg ?? "Unexpected error. Please try again later.");
      setBusy(false);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content" style={{ overflow: "visible" }}>
        <div className="box has-background-grey-lighter">
          <div className="content">
            <h3 className="title is-4" data-testid={TEST_ID_DIALOG_PORTAL_USER_TITLE}>
              {formMode}
            </h3>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="columns">
                <div className="column is-two-thirds">
                  <FormInput
                    autoFocus={formMode === NEW}
                    readOnly={formMode === EDIT}
                    id="email"
                    label="Email address (username)"
                    disabled={busy}
                    error={errors.email}
                    {...register("email")}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-one-third">
                  <FormInput
                    id="firstName"
                    label="First name"
                    disabled={busy}
                    error={errors.firstName}
                    {...register("firstName")}
                  />
                </div>
                <div className="column is-one-third">
                  <FormInput
                    id="lastName"
                    label="Last name"
                    disabled={busy}
                    error={errors.lastName}
                    {...register("lastName")}
                  />
                </div>
              </div>
              <div className={classNames("field columns is-mobile", { disabled: busy })}>
                <div className="column is-two-thirds pb-1">
                  <h6 className="label mb-0">User group</h6>
                </div>
                <div className="column pb-1">
                  <h6 className="label mb-0">Result notifications</h6>
                </div>
              </div>
              {fields.map((field, index) => {
                return (
                  <div className="columns is-mobile mb-0" key={field.id}>
                    <div className="column is-two-thirds pb-2">
                      <FormSelect
                        noMargin
                        id={`groups.${index}.groupId`}
                        label=""
                        disabled={busy}
                        control={control}
                        options={userGroupOptions[formMode]}
                        error={errors?.groups?.[index]?.groupId}
                      />
                    </div>
                    <div className="column">
                      <FormCheckbox
                        id={`groups.${index}.emailNotifications`}
                        label="Email"
                        disabled={busy}
                        {...register(`groups.${index}.emailNotifications`)}
                      />
                    </div>
                    <div className="column is-narrow pb-2">
                      {fields.length > 1 && (
                        <button
                          type="button"
                          disabled={busy}
                          className="button is-ghost has-text-danger"
                          data-testid={TEST_ID_DIALOG_REMOVE_USER_GROUP_BUTTON}
                          onClick={() => remove(index)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                disabled={busy}
                className="button is-small is-ghost px-0"
                data-testid={TEST_ID_DIALOG_ADD_USER_GROUP_BUTTON}
                onClick={() => append({ ...newUserGroupRow })}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add user group
              </button>
              <div className="buttons mt-5">
                <button
                  type="submit"
                  className={classNames("button is-primary", { "is-loading": busy })}
                  data-testid={TEST_ID_DIALOG_SAVE_USER_BUTTON}
                >
                  {formMode === NEW ? "Create new user" : "Save changes"}
                </button>
                <button
                  type="button"
                  disabled={busy}
                  className="button is-light"
                  data-testid={TEST_ID_DIALOG_CANCEL_BUTTON}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
              {!!error && <p className="notification is-danger">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortalUserFormDialog;
