import React from "react";

export const TEST_ID_PAGE_HEADER_SUBTITLE = "PageHeaderSubtitle";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

const PageHeader = ({ title, subtitle }: PageHeaderProps): JSX.Element => {
  return (
    <header className="hero">
      <div className="hero-body px-0">
        <h1 className="title">{title}</h1>
        {!!subtitle && (
          <h2 className="subtitle" data-testid={TEST_ID_PAGE_HEADER_SUBTITLE}>
            {subtitle}
          </h2>
        )}
      </div>
    </header>
  );
};

export default PageHeader;
