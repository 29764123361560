import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface MissingFieldTagProps {
  message: string;
}

const MissingFieldTag = ({ message }: MissingFieldTagProps): JSX.Element => {
  return (
    <span className="tag is-danger is-light is-rounded">
      <FontAwesomeIcon icon={faTriangleExclamation} className="mr-1" />
      {message}
    </span>
  );
};

export default MissingFieldTag;
