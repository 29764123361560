import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const ConfidentSpongeReachedStomach = (): JSX.Element => {
  const { formState, register } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="confidentSpongeReachedStomach"
      label="Are you confident that the sponge reached the stomach?"
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.confidentSpongeReachedStomach}
      {...register("confidentSpongeReachedStomach")}
    />
  );
};

export default ConfidentSpongeReachedStomach;
