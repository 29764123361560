export enum UserType {
  PORTAL = "Portal",
  PATHOLOGIST = "Pathologist",
}

export enum UserStatus {
  ENABLED = "Enabled",
  DISABLED = "Disabled",
}

export enum UserMfaStatus {
  DISABLED = "Disabled",
  PENDING = "Pending",
  ENABLED = "Enabled",
}

export interface UserAccountOperation {
  operation: UserAccountOperationType;
}

export enum UserAccountOperationType {
  ACTIVATE = "Activate",
  DEACTIVATE = "Deactivate",
  DISABLE_MFA = "DisableMfa",
  DELETE = "Delete",
  UPDATE = "Update",
}

export type UserAccountTransition = Exclude<
  UserAccountOperationType,
  UserAccountOperationType.UPDATE
>;

export const userStatusLabel: Record<UserStatus, string> = {
  [UserStatus.ENABLED]: "Active",
  [UserStatus.DISABLED]: "Deactivated",
};

export const userMfaStatusLabel: Record<UserMfaStatus, string> = {
  [UserMfaStatus.DISABLED]: "", // Not required by UI
  [UserMfaStatus.PENDING]: "MFA pending",
  [UserMfaStatus.ENABLED]: "MFA enabled",
};
