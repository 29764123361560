import { chain } from "lodash";

import { FormSelectOption } from "../components/forms/FormSelect";
import { UserMfaStatus, UserStatus } from "./UserSchema";

// For case data
export interface Pathologist {
  pathkitUserId: string;
  fullName: string;
  status: UserStatus;
  limsConsultantId: string | null;
}

// For user management
export interface PathologistUser {
  userId: string;
  userName: string;
  firstName: string | null;
  lastName: string | null;
  knownAs: string | null;
  email: string;
  status: UserStatus;
  mfaStatus: UserMfaStatus;
}

// Pathologists must have a name and a LIMS consultant mapping for dropdown lists
export const isValidPathologist = ({
  fullName,
  limsConsultantId,
}: Pathologist): boolean => {
  return fullName.trim() !== "" && limsConsultantId !== null;
};

// Pathologists must have an active user account to be assigned to new cases
export const isActivePathologist = ({ status }: Pathologist): boolean => {
  return status === UserStatus.ENABLED;
};

export const mapPathologistsToListOptions = (
  pathologists: Pathologist[]
): FormSelectOption[] => {
  return (
    chain(pathologists)
      // Extract only the label and value properties required for a dropdown list
      .map(({ fullName, pathkitUserId }) => ({
        label: fullName,
        value: pathkitUserId,
      }))
      // Sort pathologists alphabetically by name
      .sortBy("label")
      .value()
  );
};
