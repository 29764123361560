import React from "react";
import { useFormContext } from "react-hook-form";

import WarningMessage from "../../../components/atoms/WarningMessage";
import FormDateInput from "../../../components/forms/FormDateInput";
import { isDateMoreThanOneMonthAgo } from "../../../helpers/validation";
import { FormMode } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

export const TEST_ID_FORM_PROCEDURE_DATE_WARNING = "FormProcedureDateWarning";

interface ProcedureDateProps {
  formMode: FormMode;
}

const ProcedureDate = ({ formMode }: ProcedureDateProps): JSX.Element => {
  const { control, formState, watch } = useFormContext<CaseUiData>();
  const { procedureDate } = watch();

  // TODO PLAT-5689: Refactor this...
  // Show date warning when procedure date may be wrong at booking in
  const procedureDateWarning: string | false =
    formMode === "create" &&
    isDateMoreThanOneMonthAgo(procedureDate) &&
    "This was over a month ago";

  return (
    <>
      <FormDateInput
        id="procedureDate"
        label="Date of procedure"
        control={control}
        error={formState.errors.procedureDate}
      />
      {!!procedureDateWarning && (
        <WarningMessage testId={TEST_ID_FORM_PROCEDURE_DATE_WARNING}>
          {procedureDateWarning}
        </WarningMessage>
      )}
    </>
  );
};

export default ProcedureDate;
