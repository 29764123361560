import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const HasEoEDiagnosis = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="hasEoEDiagnosis"
      label="Is there an Eosinophilic oesophagitis (EoE) diagnosis?"
      options={CaseSchemaOptions.yesNoUnknownUnanswered}
      error={formState.errors.hasEoEDiagnosis}
      {...register("hasEoEDiagnosis")}
    />
  );
};

export default HasEoEDiagnosis;
