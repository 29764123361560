import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const NumberOfFailedAttempts = (): JSX.Element => {
  const { register, formState, setValue, watch } = useFormContext<CaseUiData>();
  const { numberOfFailedAttempts } = watch();

  useEffect(() => {
    // Clear legacy field values (e.g. "2") on component mount
    const allowedValues: string[] = CaseSchemaOptions.numberOfFailedAttempts;
    if (!allowedValues.includes(numberOfFailedAttempts)) {
      setValue("numberOfFailedAttempts", "");
    }
  }, []);

  // Maps options "None" to "No" and "1" to "Yes"
  const numberOfFailedAttemptsLabels: Record<string, Answer> = {
    [CaseSchemaOptions.numberOfFailedAttempts[0]]: Answer.NO,
    [CaseSchemaOptions.numberOfFailedAttempts[1]]: Answer.YES,
  };

  const getNumberOfFailedAttemptsLabel = (option: string) => {
    return numberOfFailedAttemptsLabels[option];
  };

  return (
    <RadioGroup
      id="numberOfFailedAttempts"
      label="Was there a failed attempt?"
      // Reverse the order of "None" and "1" in the case schema to match "Yes" and "No"
      options={[
        ...CaseSchemaOptions.numberOfFailedAttempts[1],
        CaseSchemaOptions.numberOfFailedAttempts[0],
      ]}
      getOptionLabel={getNumberOfFailedAttemptsLabel}
      error={formState.errors.numberOfFailedAttempts}
      {...register("numberOfFailedAttempts", {
        onChange: () => {
          setValue("reasonsForFailedAttempt", [], setValueConfig);
          setValue("deviceIdForFailedAttempt", "", setValueConfig);
        },
      })}
    />
  );
};

export default NumberOfFailedAttempts;
