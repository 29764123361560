import React from "react";
import { useFormContext } from "react-hook-form";

import FormSelect, { FormSelectOption } from "../../../components/forms/FormSelect";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface LimsClinicianProps {
  clinicians: FormSelectOption[];
}

const LimsClinician = ({ clinicians }: LimsClinicianProps): JSX.Element => {
  const { control } = useFormContext<CaseUiData>();

  return (
    <FormSelect
      id="clinician"
      label="LIMS clinician"
      disabled
      control={control}
      options={clinicians}
      placeholder=""
      help="⚠ Controlled by user group"
    />
  );
};

export default LimsClinician;
