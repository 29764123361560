import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import {
  GetUsersResponse,
  isPathologistUserResults,
  isPortalUserResults,
} from "../schemas/ApiSchema";
import { PathologistUser } from "../schemas/PathologistSchema";
import { PortalUser } from "../schemas/PortalUserSchema";
import { UserType } from "../schemas/UserSchema";
import { CustomError, dataService } from "../services/data.service";

export interface PortalUserResults extends GetUsersResponse {
  users: PortalUser[];
}

export interface PathologistUserResults extends GetUsersResponse {
  users: PathologistUser[];
}

type UserTypeResultsMap = {
  [UserType.PORTAL]: PortalUserResults;
  [UserType.PATHOLOGIST]: PathologistUserResults;
};

type UserResults = {
  [K in UserType]: UserTypeResultsMap[K];
};

export interface UserListState {
  status: "pending" | "succeeded" | "failed";
  error?: CustomError;
  listType: UserType;
  results: UserResults;
}

const initialState: UserListState = {
  status: "pending",
  error: undefined,
  listType: UserType.PORTAL,
  results: {
    [UserType.PORTAL]: { page: 1, pageSize: 50, totalPages: 1, totalUsers: 0, users: [] },
    [UserType.PATHOLOGIST]: {
      page: 1,
      pageSize: 50,
      totalPages: 1,
      totalUsers: 0,
      users: [],
    },
  },
};

export const fetchUsers = createAsyncThunk<
  GetUsersResponse,
  UserType,
  {
    rejectValue: CustomError;
    state: RootState;
  }
>("search/fetchUsers", async (userType, { getState, rejectWithValue }) => {
  const { page } = getState().userList.results[userType];
  const response = await dataService.getUsers(userType, page);
  return response.data ?? rejectWithValue(response.error);
});

export const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    updateListType: (state, action: PayloadAction<UserType>) => {
      state.listType = action.payload;
    },
    updatePageNumber: (
      state,
      action: PayloadAction<{ page: number; listType: UserType }>
    ) => {
      const { page, listType } = action.payload;
      const { totalPages } = state.results[listType];
      if (page > 0 && page <= totalPages) {
        state.results[listType].page = page;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.error = undefined;
        state.status = "pending";
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<GetUsersResponse>) => {
        const { payload } = action;
        if (isPortalUserResults(payload)) {
          Object.assign(state.results[UserType.PORTAL], payload);
        }
        if (isPathologistUserResults(payload)) {
          Object.assign(state.results[UserType.PATHOLOGIST], payload);
        }
        state.status = "succeeded";
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

// Actions
export const { updateListType, updatePageNumber } = userListSlice.actions;

export default userListSlice.reducer;
