import { isBefore, subMonths } from "date-fns";

export const patterns = {
  recordNumber: /^\d{2}P\d{5}$/i,
  labNumber: /^\d{2}CYT\d{5}$/i,
  initials: /^[\p{Lu}]{2}$/u, // Allow diacritics on uppercase letters
  participantIdBest4Reflux: /^SCR-\d{3}-\d{3}$/i,
  participantIdBest4Surveillance: /^SUR-[A-Z]{3}-\d{3}$/i,
};

export const isDateMoreThanOneMonthAgo = (date: Date) => {
  const oneMonthAgo = subMonths(new Date(), 1);
  return isBefore(date, oneMonthAgo);
};
