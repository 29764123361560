import { Authenticator, Text, View } from "@aws-amplify/ui-react";
import { DefaultComponents } from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
import React from "react";

import Heading from "./AuthenticatorHeading";
import PasswordStrengthPanel from "./PasswordStrengthPanel";

// See https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
const Components: DefaultComponents = {
  ConfirmSignIn: {
    Header() {
      return <Heading>Multi-factor authentication</Heading>;
    },
  },
  ForgotPassword: {
    Header() {
      return <Heading>Reset password</Heading>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <View marginBottom="xs">
          <Heading>Reset password</Heading>
          <Text marginTop="small">Check your email for a password reset code.</Text>
        </View>
      );
    },
    Footer() {
      return <PasswordStrengthPanel className="my-4" />;
    },
  },
  ForceNewPassword: {
    Header() {
      return <Heading>Create a new password</Heading>;
    },
    FormFields() {
      return (
        <>
          <Authenticator.ForceNewPassword.FormFields />
          <PasswordStrengthPanel />
        </>
      );
    },
  },
};

export default Components;
