import { union } from "lodash";

import { RequestFormType } from "./RequestForms";

// Legacy forms
const LegacyRefluxForms: RequestFormType[] = [RequestFormType.TRF_1_V2];
const LegacySurveillanceForms: RequestFormType[] = [
  RequestFormType.TRF_2_V2,
  RequestFormType.TRF_4_V1,
];

// Current forms
const CurrentRefluxForms: RequestFormType[] = [RequestFormType.TRF_3_V1]; // TODO PLAT-5722: Later to include TRF_1_V3

// BEST4 forms
const Best4RefluxForms: RequestFormType[] = [RequestFormType.TRF_3_V1];
const Best4SurveillanceForms: RequestFormType[] = [
  RequestFormType.TRF_4_V1,
  RequestFormType.TRF_4_V2,
];
const AllBest4Forms: RequestFormType[] = union(Best4RefluxForms, Best4SurveillanceForms);

// All reflux forms
const AllRefluxForms: RequestFormType[] = union(CurrentRefluxForms, LegacyRefluxForms);
// All Surveillance forms
const AllSurveillanceForms: RequestFormType[] = union(
  LegacySurveillanceForms,
  Best4SurveillanceForms
);

const AllLegacyForms: RequestFormType[] = union(
  LegacyRefluxForms,
  LegacySurveillanceForms
);

export const isLegacyForm = (form: RequestFormType): boolean => {
  return AllLegacyForms.includes(form);
};

export const isRefluxForm = (form: RequestFormType): boolean => {
  return AllRefluxForms.includes(form);
};

export const isCurrentRefluxForm = (form: RequestFormType): boolean => {
  return CurrentRefluxForms.includes(form);
};

export const isLegacyRefluxForm = (form: RequestFormType): boolean => {
  return LegacyRefluxForms.includes(form);
};

export const isLegacySurveillanceForm = (form: RequestFormType): boolean => {
  return LegacySurveillanceForms.includes(form);
};

export const isSurveillanceForm = (form: RequestFormType): boolean => {
  return AllSurveillanceForms.includes(form);
};

export const isBest4Form = (form: RequestFormType): boolean => {
  return AllBest4Forms.includes(form);
};

export const isBest4RefluxForm = (form: RequestFormType): boolean => {
  return Best4RefluxForms.includes(form);
};

export const isBest4SurveillanceForm = (form: RequestFormType): boolean => {
  return Best4SurveillanceForms.includes(form);
};
