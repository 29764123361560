import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const WasTensionOnThread = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="wasTensionOnThread"
      label="Was there tension on the thread throughout withdrawal?"
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.wasTensionOnThread}
      {...register("wasTensionOnThread")}
    />
  );
};

export default WasTensionOnThread;
