import React from "react";
import { useFormContext } from "react-hook-form";

import FormCheckbox from "../../../components/forms/FormCheckbox";
import { CaseUiData } from "../../schema/CaseUiSchema";

const HasAdditionalClinicalInformation = (): JSX.Element => {
  const { register } = useFormContext<CaseUiData>();

  return (
    <FormCheckbox
      addMargin
      id="hasAdditionalClinicalInformation"
      heading="Additional clinical details"
      label="More information on request form"
      {...register("hasAdditionalClinicalInformation")}
    />
  );
};

export default HasAdditionalClinicalInformation;
