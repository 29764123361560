import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface Best4ParticipantIdProps {
  help?: string;
}

const Best4ParticipantId = ({ help }: Best4ParticipantIdProps): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <FormInput
      addMargin
      id="patientResearchId"
      label="Participant ID"
      className="is-uppercase"
      help={help}
      error={formState.errors.patientResearchId}
      {...register("patientResearchId", {
        setValueAs: (value: string) => value.toUpperCase(),
      })}
    />
  );
};

export default Best4ParticipantId;
