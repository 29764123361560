// See https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
const FormFields = {
  signIn: {
    username: {
      placeholder: "Enter your username",
      isRequired: true,
      autoFocus: true,
    },
    password: {
      placeholder: "Enter your password",
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      type: "text", // Remove number arrow buttons
      label: "Authenticator app code",
      placeholder: "6-digit code",
      isRequired: true,
      autoFocus: true,
      maxLength: 6,
    },
  },
  forceNewPassword: {
    password: {
      label: "New password",
      placeholder: "",
      isRequired: true,
      autoFocus: true,
    },
    confirm_password: {
      label: "Retype new password",
      placeholder: "",
      isRequired: true,
    },
  },
  forgotPassword: {
    username: {
      label: "Username",
      placeholder: "",
      isRequired: true,
      autoFocus: true,
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: "Code",
      placeholder: "6-digit code",
      isRequired: true,
      autoFocus: true,
      maxLength: 6,
    },
    password: {
      label: "New password",
      placeholder: "",
      isRequired: true,
    },
    confirm_password: {
      label: "Retype new password",
      placeholder: "",
      isRequired: true,
    },
  },
};

export default FormFields;
