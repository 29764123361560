import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const SmokingHistory = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="smokingHistory"
      label="Smoking history"
      options={CaseSchemaOptions.smokingHistory}
      error={formState.errors.smokingHistory}
      {...register("smokingHistory")}
    />
  );
};

export default SmokingHistory;
