import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CURRENT_YEAR, getInitialsFromPatientName } from "../../../helpers/strings";
import { patterns } from "../../../helpers/validation";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import UseRecordNumberForPatientName from "./UseRecordNumberForPatientName";

interface RecordNumberProps {
  allowUseRecordNumberForPatientName?: boolean;
}

const RecordNumber = ({
  allowUseRecordNumberForPatientName,
}: RecordNumberProps): JSX.Element => {
  const { register, formState, getValues, setValue, watch } =
    useFormContext<CaseUiData>();

  useEffect(() => {
    if (!allowUseRecordNumberForPatientName) {
      setValue("useRecordNumberForPatientName", false, setValueConfig);
    }
  }, []);

  const { useRecordNumberForPatientName } = watch();

  const fillPatientNameFromPotId = (): void => {
    // Parse a record number into first name (e.g. 22P) and surname (e.g. 12345)
    const recordNumber: string = getValues("recordNumber");
    if (recordNumber.match(patterns.recordNumber)) {
      const firstName = recordNumber.substring(0, 3);
      const surname = recordNumber.substring(3, 8);
      const initials = getInitialsFromPatientName(firstName, surname);
      setValue("patientFirstName", firstName, setValueConfig);
      setValue("patientSurname", surname, setValueConfig);
      setValue("patientInitials", initials, setValueConfig);
    }
  };

  return (
    <>
      <FormInput
        id="recordNumber"
        label="Cell preservation kit ID"
        maxLength={8}
        className="is-medium is-uppercase"
        icon={faQrcode}
        placeholder={`${CURRENT_YEAR}P00001`}
        error={formState.errors.recordNumber}
        {...register("recordNumber", {
          onChange: () => {
            if (useRecordNumberForPatientName) fillPatientNameFromPotId();
          },
          setValueAs: (value: string) => value.toUpperCase(),
        })}
      />
      {allowUseRecordNumberForPatientName ? (
        <UseRecordNumberForPatientName
          onChange={(e) => {
            if (e.target.checked) fillPatientNameFromPotId();
          }}
        />
      ) : (
        <input
          type="hidden"
          id="useRecordNumberForPatientName"
          {...register("useRecordNumberForPatientName", { value: false })}
        />
      )}
    </>
  );
};

export default RecordNumber;
