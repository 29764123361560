import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useFormContext } from "react-hook-form";

import FormTextArea from "../../../components/forms/FormTextArea";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface MacroProps {
  formLocked?: boolean;
}

export const MacroTextButtons: { label: string; value: string; testId: string }[] = [
  {
    label: "No descriptive features",
    value: "No descriptive features noted in specimen during laboratory procedures.",
    testId: "MacroNormalButton",
  },
  {
    label: "Debris / food matter",
    value: "Debris/food matter noted in specimen during laboratory procedures.",
    testId: "MacroDebrisButton",
  },
];

const Macro = ({ formLocked }: MacroProps): JSX.Element => {
  const { register, formState, setValue } = useFormContext<CaseUiData>();

  return (
    <>
      <FormTextArea
        id="macro"
        label="Macro"
        disabled={formLocked}
        error={formState.errors.macro}
        {...register("macro")}
      />
      {!formLocked && (
        <div className="buttons are-small">
          {MacroTextButtons.map(({ label, value, testId }) => (
            <button
              type="button"
              key={testId}
              className="button is-link is-light"
              data-testid={testId}
              onClick={() => setValue("macro", value, setValueConfig)}
            >
              <FontAwesomeIcon icon={faAdd} className="mr-2" />
              {label}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default Macro;
