import { faCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import { UserStatus, userStatusLabel } from "../../schemas/UserSchema";
import LabelledIcon from "../atoms/LabelledIcon";

interface UserStatusIndicatorProps {
  status: UserStatus;
}

const UserStatusIndicator = ({ status }: UserStatusIndicatorProps): JSX.Element => {
  return (
    <LabelledIcon
      icon={faCircle}
      label={userStatusLabel[status]}
      color={status === UserStatus.ENABLED ? "success" : "danger"}
    />
  );
};

export default UserStatusIndicator;
