import classNames from "classnames";
import React from "react";

interface FormCheckboxProps extends React.PropsWithRef<JSX.IntrinsicElements["input"]> {
  id: string;
  label: string;
  heading?: string;
  addMargin?: boolean;
}

const headedCheckboxStyles = {
  marginRight: "0.688rem",
  marginBottom: "0.5rem",
};

const FormCheckbox = React.forwardRef<HTMLInputElement, FormCheckboxProps>(
  ({ id, label, heading, addMargin, ...props }, ref) => (
    <div className={classNames("field", { "mb-5": addMargin, disabled: props.disabled })}>
      {!!heading && <h4 className="label mb-3">{heading}</h4>}
      <label className="checkbox" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          ref={ref}
          style={heading ? headedCheckboxStyles : {}}
          {...props}
        />
        {label}
      </label>
    </div>
  )
);

FormCheckbox.displayName = "FormCheckbox";

export default FormCheckbox;
