import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import FormCheckbox from "../../../components/forms/FormCheckbox";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface PatientIdentifierNotProvidedProps {
  onChange: (x: ChangeEvent<HTMLInputElement>) => void;
}

const PatientIdentifierNotProvided = ({
  onChange,
}: PatientIdentifierNotProvidedProps): JSX.Element => {
  const { register } = useFormContext<CaseUiData>();

  return (
    <FormCheckbox
      id="patientIdentifierNotProvided"
      label="None"
      {...register("patientIdentifierNotProvided", { onChange })}
    />
  );
};

export default PatientIdentifierNotProvided;
