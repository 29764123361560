import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent } from "react";

import { PortalUserDialogType, TEST_ID_USER_LIST_TABLE } from "../../routes/UserList";
import { PortalUser } from "../../schemas/PortalUserSchema";
import PortalUserRow from "./PortalUserRow";

interface PortalUserTableProps {
  users: PortalUser[];
  openUserDialog: (e: MouseEvent, d: PortalUserDialogType, id?: string) => void;
}

const PortalUserTable = ({
  users,
  openUserDialog,
}: PortalUserTableProps): JSX.Element => {
  return (
    <table className="table is-fullwidth" data-testid={TEST_ID_USER_LIST_TABLE}>
      <thead>
        <tr>
          <th>
            Name <FontAwesomeIcon icon={faCaretUp} className="ml-1" />
          </th>
          <th>User group</th>
          <th>Last active</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <PortalUserRow user={user} key={user.userId} openUserDialog={openUserDialog} />
        ))}
      </tbody>
    </table>
  );
};

export default PortalUserTable;
