import classNames from "classnames";
import React from "react";
import { FieldError } from "react-hook-form";

interface FormTextAreaProps
  extends React.PropsWithRef<JSX.IntrinsicElements["textarea"]> {
  id: string;
  label: string;
  rows?: number;
  addMargin?: boolean;
  help?: string;
  error?: FieldError;
}

const FormTextArea = React.forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ id, label, rows = 6, addMargin, help, error, ...props }, ref) => {
    return (
      <div
        className={classNames("field", { "mb-5": addMargin, disabled: props.disabled })}
      >
        <label htmlFor={id} className="label">
          {label}
        </label>
        <textarea
          id={id}
          ref={ref}
          rows={rows}
          aria-invalid={error ? "true" : "false"}
          className={classNames("textarea", { "is-danger": error })}
          {...props}
        />
        {help && !error && <p className="help">{help}</p>}
        {error && <p className="help has-text-danger">{error.message}</p>}
      </div>
    );
  }
);

FormTextArea.displayName = "FormTextArea";

export default FormTextArea;
