import React from "react";

import Fieldset from "../../components/forms/Fieldset";
import { FormSelectOption } from "../../components/forms/FormSelect";
import { UseClinicianForProcedureSite } from "../fields/deprecated";
import { LimsCaseOrigin, LimsClinician } from "../fields/lims";
import { UserGroup } from "../fields/recipient";

interface ReportRecipientProps {
  allowUseClinicianForProcedureSite?: boolean;
  disabled: boolean;
  clinicians: FormSelectOption[];
  caseOrigins: FormSelectOption[];
}

const ReportRecipient = ({
  allowUseClinicianForProcedureSite,
  disabled,
  clinicians,
  caseOrigins,
}: ReportRecipientProps): JSX.Element => {
  return (
    <Fieldset title="Report recipient" className="tile is-child" disabled={disabled}>
      <UserGroup disabled={disabled} />
      <UseClinicianForProcedureSite
        allowUseClinicianForProcedureSite={allowUseClinicianForProcedureSite}
        clinicians={clinicians}
      />
      <LimsClinician clinicians={clinicians} />
      <LimsCaseOrigin caseOrigins={caseOrigins} />
    </Fieldset>
  );
};

export default ReportRecipient;
