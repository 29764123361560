import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import logo from "../../assets/Cyted-Logo-RGB_White.png";
import { useHandleSignOut } from "../../helpers/auth";
import { RootState } from "../../store";
import EnvironmentTag from "../atoms/EnvironmentTag";

const Navbar = (): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const { username } = useSelector((state: RootState) => state.auth);

  const toggleNavbar = async (e: MouseEvent) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  const handleSignOut = useHandleSignOut();

  return (
    <nav role="navigation" className="navbar is-black" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={logo} alt="Cyted logo" />
        </Link>
        <a
          href=""
          role="button"
          className={classNames("navbar-burger", { "is-active": isActive })}
          id="navbarToggle"
          aria-label="menu"
          aria-expanded={isActive}
          onClick={toggleNavbar}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={classNames("navbar-menu", { "is-active": isActive })}>
        <div className="navbar-start">
          <Link to="cases" className="navbar-item">
            Cases
          </Link>
          <Link to="users" className="navbar-item">
            Users
          </Link>
          <Link to="groups" className="navbar-item">
            User groups
          </Link>
        </div>
        {username && (
          <div className="navbar-end">
            <div className="navbar-item is-hidden-mobile mr-2">
              <EnvironmentTag />
            </div>
            <Link to="profile" className="navbar-item">
              <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
              {username}
            </Link>
            <a href="" className="navbar-item" onClick={handleSignOut}>
              <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" />
              Log out
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
