import { FormSelectOption } from "../../components/forms/FormSelect";

export enum RequestFormType {
  TRF_1_V2 = "TRF-1 (Reflux)",
  TRF_1_V3 = "TRF-1 Version 3 (Reflux)",
  TRF_2_V2 = "TRF-2 (Barrett’s surveillance)",
  TRF_2_V3 = "TRF-2 Version 3 (Barrett’s surveillance)",
  TRF_3_V1 = "TRF-3 Version 1 (Reflux - BEST4)",
  TRF_4_V1 = "TRF-4 Version 1 (Barrett’s surveillance - BEST4)",
  TRF_4_V2 = "TRF-4 Version 2 (Barrett’s surveillance - BEST4)",
}

export const RequestFormOptions: FormSelectOption[] = [
  {
    label: "Reflux - TRF-1 Version 2",
    value: RequestFormType.TRF_1_V2,
  },
  {
    label: "Barrett’s surveillance - TRF-2 Version 2",
    value: RequestFormType.TRF_2_V2,
  },
  {
    label: "[BEST4] Reflux - TRF-3 Version 1",
    value: RequestFormType.TRF_3_V1,
  },
  {
    label: "[BEST4] Barrett’s surveillance - TRF-4 Version 1",
    value: RequestFormType.TRF_4_V1,
  },
];

export const Best4ScreeningStudyArms: string[] = [
  "B4_SCR_baseline",
  "B4_SCR_baseline_repeat",
];

export const Best4SurveillanceStudyArms: string[] = [
  "B4_SUR_baseline",
  "B4_SUR_baseline_repeat",
  "B4_SUR_18month",
  "B4_SUR_18month_repeat",
  "B4_SUR_36month",
  "B4_SUR_36month_repeat",
];
