import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { getPatientNameFromInitials } from "../../helpers/strings";
import { RequestFormProps } from "../FormComponent";
import { setValueConfig } from "../FormWrapper";
import { DeviceDetails, LabUseOnly, ReportRecipient } from "../blocks";
import ProcedureDetails from "../blocks/ProcedureDetails";
import { Best4ParticipantId, Best4StudyArm } from "../fields/best4";
import { HasAdditionalClinicalInformation, HasEoEDiagnosis } from "../fields/clinical";
import {
  PatientDateOfBirth,
  PatientIdentifier,
  PatientInitials,
  PatientSex,
} from "../fields/patient";
import { CaseUiData } from "../schema/CaseUiSchema";
import { Answer } from "../schema/FormAnswers";
import { Best4ScreeningStudyArms } from "../schema/RequestForms";

const TRF_3_Version_1 = ({
  formMode,
  formLocked,
  limsOptions: { specimens, clinicians, caseOrigins, consultants },
}: RequestFormProps): JSX.Element => {
  const { register, unregister, getValues, setValue } = useFormContext<CaseUiData>();

  useEffect(() => {
    // Discard any fields in form state which aren't used in this form
    unregister([
      "useRecordNumberForPatientName",
      "useClinicianForProcedureSite",
      "patientIdentifierAlternative",
      "patientIdentifierNotProvided",
      "hadEndoscopicTreatment",
      "hadDysplasia",
      "dysplasiaGrade",
      "dateLastEndoscopy",
      "circumferentialLength",
      "maximalLength",
      "didPatientSwallow",
      "didSpongeExpand",
      "wasTensionOnThread",
      "isDebrisOnSponge",
      "takesAnticoagulants",
      "isTakingPPI",
      "hasRefluxSymptoms",
      "smokingHistory",
    ]);
    // Refresh the (hidden) patient name fields to use only initials and
    // update patient identifier if "Not provided"
    updatePatientName();
    updatePatientIdentifier();
    updateFailedAttemptsFields();
  }, []);

  const updatePatientIdentifier = (): void => {
    if (getValues("patientIdentifier") === Answer.UNANSWERED) {
      setValue("patientIdentifier", "", setValueConfig);
    }
  };

  const updateFailedAttemptsFields = (): void => {
    // The important thing is "was there a failed attempt" rather than the number of failed attempts.
    // Therefore, when switching to a TRF-3 request form, we want to map "Number of failed attempts: 2"
    // to "Was there a failed attempt? Yes". For TRF-3 request forms, a value of "1" represents "Yes"
    // and a value of "None" represents "No".
    if (getValues("numberOfFailedAttempts") === Answer.TWO) {
      setValue("numberOfFailedAttempts", Answer.ONE, setValueConfig);
    }

    if (getValues("numberOfFailedAttempts") === Answer.NONE) {
      setValue("deviceIdForFailedAttempt", null, setValueConfig);
      setValue("reasonsForFailedAttempt", null, setValueConfig);
    } else if (getValues("numberOfFailedAttempts") === Answer.ONE) {
      setValue("deviceIdForFailedAttempt", "", setValueConfig);
      setValue("reasonsForFailedAttempt", [], setValueConfig);
    }
  };

  const updatePatientName = (): void => {
    const initials = getValues("patientInitials").trim();
    const { firstName, surname } = getPatientNameFromInitials(initials);
    setValue("patientFirstName", firstName, setValueConfig);
    setValue("patientSurname", surname, setValueConfig);
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <DeviceDetails disabled={formLocked} specimens={specimens} />
        </div>
        <div className="tile is-parent">
          <ReportRecipient
            disabled={formLocked}
            clinicians={clinicians}
            caseOrigins={caseOrigins}
          />
        </div>
      </div>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <Fieldset
            title="Participant details"
            className="tile is-child"
            disabled={formLocked}
          >
            <div className="columns">
              <div className="column is-8">
                <PatientIdentifier />
              </div>
              <div className="column is-4">
                <PatientInitials help="2 letters only" onChange={updatePatientName} />
                <input
                  type="hidden"
                  id="patientFirstName"
                  {...register("patientFirstName")}
                />
                <input
                  type="hidden"
                  id="patientSurname"
                  {...register("patientSurname")}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-8">
                <PatientDateOfBirth />
              </div>
              <div className="column is-4">
                <PatientSex />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="tile is-parent">
          <Fieldset title="BEST4" className="tile is-child" disabled={formLocked}>
            <Best4ParticipantId help="e.g. SCR-123-456" />
            <Best4StudyArm disabled={formLocked} studyArms={Best4ScreeningStudyArms} />
          </Fieldset>
        </div>
      </div>

      <Fieldset title="Clinical information" disabled={formLocked}>
        <HasEoEDiagnosis />
        <HasAdditionalClinicalInformation />
      </Fieldset>

      <ProcedureDetails formMode={formMode} formLocked={formLocked} />

      <LabUseOnly formMode={formMode} disabled={formLocked} consultants={consultants} />
    </>
  );
};

export default TRF_3_Version_1;
