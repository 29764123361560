import { ChangeEvent, useEffect, useState } from "react";

/**
 * Amplify UI's Authenticator component does not currently expose
 * password field events, so this custom hook adds an event listener
 * in order to return the current value of the "New password" field.
 *
 * Returns null if the input element doesn't exist.
 */
const usePasswordField = (): string | null => {
  const [password, setPassword] = useState<string | null>(null);

  useEffect(() => {
    const passwordField: HTMLInputElement | null =
      document.querySelector("input[name=password]");

    const handleChange = (e: Event): void => {
      const { target } = e as unknown as ChangeEvent<HTMLInputElement>;
      setPassword(target.value);
    };

    if (passwordField) {
      setPassword("");
      passwordField.addEventListener("input", handleChange);
    }

    return () => {
      if (passwordField) {
        passwordField.removeEventListener("input", handleChange);
      }
    };
  }, []);

  return password;
};

export default usePasswordField;
