import React, { useEffect } from "react";

import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";

interface ErrorPageProps {
  title?: string;
  subtitle?: string;
}

const ErrorPage = ({ title, subtitle }: ErrorPageProps): JSX.Element => {
  useEffect(() => {
    document.title = `Error | ${SITE_TITLE}`;
  }, []);

  return (
    <div className="container">
      <PageHeader title={title ?? "Error"} subtitle={subtitle} />
      <div className="columns content">
        <div className="column is-two-thirds is-half-widescreen">
          <p>An unexpected error occurred while processing your request.</p>
          <p>
            Please check the browser address bar to ensure that there are no incorrect or
            missing characters in the URL, or navigate back and try a different link.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
