import classNames from "classnames";
import React from "react";

import {
  DateLastEndoscopy,
  DysplasiaGrade,
  HadDysplasia,
  PragueClassification,
} from "../fields/clinical";
import {
  HadEndoscopicTreatment,
  IsTakingPPI,
  SmokingHistory,
} from "../fields/deprecated";

interface BarrettsDetailsProps {
  disabled: boolean;
}

const BarrettsDetails = ({ disabled }: BarrettsDetailsProps): JSX.Element => {
  return (
    <div className="columns mb-1">
      <div className="column pb-0">
        <HadDysplasia />
        <DysplasiaGrade />
        <HadEndoscopicTreatment />
        <IsTakingPPI />
        <SmokingHistory />
      </div>
      <div className={classNames("field column", { disabled })}>
        <div className="columns is-mobile">
          <div className="column is-half">
            <DateLastEndoscopy />
          </div>
        </div>
        <PragueClassification disabled={disabled} />
      </div>
    </div>
  );
};

export default BarrettsDetails;
