import React from "react";
import { useFormContext } from "react-hook-form";

import FormSelect, { FormSelectOption } from "../../../components/forms/FormSelect";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { DeviceBrand } from "../../schema/FormAnswers";

interface LimsSpecimenProps {
  disabled: boolean;
  specimens: FormSelectOption[];
}

export const inferDeviceBrandFromSpecimen = (
  specimenLabel?: string
): DeviceBrand | null => {
  const deviceBrand = Object.values(DeviceBrand).find((deviceBrand) =>
    specimenLabel?.toUpperCase().includes(deviceBrand.toUpperCase())
  );
  // deviceBrand should never be null because the filtered 'specimen' dropdown
  // list options in Dropdowns.kt should always correspond to a DeviceBrand. The
  // API will correctly reject submissions without a deviceBrand as this would make
  // it impossible to determine which specimen details report to save to the LIMS.
  return deviceBrand ?? null;
};

const LimsSpecimen = ({ disabled, specimens }: LimsSpecimenProps): JSX.Element => {
  const { register, control, formState, setValue } = useFormContext<CaseUiData>();

  const setDeviceBrand = (specimenName?: string): void => {
    const deviceBrand = inferDeviceBrandFromSpecimen(specimenName);
    setValue("deviceBrand", deviceBrand);
  };

  return (
    <>
      <FormSelect
        id="specimen"
        label="Specimen"
        disabled={disabled}
        control={control}
        options={specimens}
        error={formState.errors.specimen}
        onChange={(specimen) => setDeviceBrand(specimen?.label)}
      />
      <input type="hidden" id="deviceBrand" {...register("deviceBrand")} />
    </>
  );
};

export default LimsSpecimen;
