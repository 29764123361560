import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const DysplasiaGrade = (): JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { hadDysplasia } = watch();

  return (
    <RadioGroup
      indented
      id="dysplasiaGrade"
      label="If Yes, what was the dysplasia grade?"
      disabled={hadDysplasia !== Answer.YES}
      options={CaseSchemaOptions.dysplasiaGrade}
      error={formState.errors.dysplasiaGrade}
      {...register("dysplasiaGrade")}
    />
  );
};

export default DysplasiaGrade;
