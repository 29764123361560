import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import FormCheckbox from "../../../components/forms/FormCheckbox";
import { FormSelectOption } from "../../../components/forms/FormSelect";
import { CHECK_LIMS } from "../../../helpers/strings";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface UseClinicianForProcedureSiteProps {
  allowUseClinicianForProcedureSite?: boolean;
  clinicians: FormSelectOption[];
}

const UseClinicianForProcedureSite = ({
  allowUseClinicianForProcedureSite,
  clinicians,
}: UseClinicianForProcedureSiteProps): JSX.Element => {
  const { register, setValue, watch } = useFormContext<CaseUiData>();

  // Watchers for interrelated fields
  const {
    clinician: watchClinician,
    useClinicianForProcedureSite: watchUseClinicianForProcedureSite,
  } = watch();

  useEffect(() => {
    if (!allowUseClinicianForProcedureSite) {
      setValue("useClinicianForProcedureSite", false, setValueConfig);
    }
  }, []);

  // Update practitioner (procedure) location field when clinician selection changes
  useEffect(() => {
    fillProcedureSiteFromClinician(watchUseClinicianForProcedureSite);
  }, [watchClinician]);

  const fillProcedureSiteFromClinician = (checked?: boolean): void => {
    if (checked) {
      const clinician = clinicians.find((c) => c.value === watchClinician);
      // Never write [Check LIMS] into the practitionerLocation field
      if (clinician && clinician.label !== CHECK_LIMS) {
        setValue("practitionerLocation", clinician.label, setValueConfig);
      }
    }
  };

  if (allowUseClinicianForProcedureSite) {
    return (
      <FormCheckbox
        addMargin
        id="useClinicianForProcedureSite"
        label="Use clinician as procedure site"
        disabled={!watchClinician}
        {...register("useClinicianForProcedureSite", {
          onChange: (e) => fillProcedureSiteFromClinician(e.target.checked),
        })}
      />
    );
  } else {
    return (
      <>
        <br />
        <input
          type="hidden"
          id="useClinicianForProcedureSite"
          {...register("useClinicianForProcedureSite", { value: false })}
        />
      </>
    );
  }
};

export default UseClinicianForProcedureSite;
