import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const HadEndoscopicTreatment = (): JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="hadEndoscopicTreatment"
      label="Has the patient ever had endoscopic treatment?"
      options={CaseSchemaOptions.yesNoUnknownUnanswered}
      error={formState.errors.hadEndoscopicTreatment}
      {...register("hadEndoscopicTreatment")}
    />
  );
};

export default HadEndoscopicTreatment;
