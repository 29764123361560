import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import FormSelect from "../../../components/forms/FormSelect";
import {
  selectUserGroups,
  selectValidUserGroupOptions,
} from "../../../store/metadataSlice";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface UserGroupProps {
  disabled: boolean;
}

const UserGroup = ({ disabled }: UserGroupProps): JSX.Element => {
  const { register, control, formState, setValue } = useFormContext<CaseUiData>();

  // Redux
  const userGroups = useSelector(selectUserGroups);
  const userGroupOptions = useSelector(selectValidUserGroupOptions);

  const setLimsClinicianAndCaseOrigin = (groupId?: string): void => {
    const match = userGroups.find(({ userGroupId }) => userGroupId === groupId);
    if (match?.procurementRoutes[0]) {
      const { limsClinicianId, limsCaseOriginId } = match.procurementRoutes[0];
      if (limsClinicianId && limsCaseOriginId) {
        setValue("clinician", limsClinicianId, setValueConfig);
        setValue("caseOrigin", limsCaseOriginId, setValueConfig);
      }
    }
  };

  const setHiddenCaseOriginName = (userGroupName?: string): void => {
    setValue("caseOriginName", userGroupName ?? "");
  };

  return (
    <>
      <FormSelect
        noMargin
        id="userGroupId"
        label="User group"
        disabled={disabled}
        control={control}
        options={userGroupOptions}
        error={formState.errors.userGroupId}
        onChange={(userGroup) => {
          const { label: groupName, value: groupId } = userGroup || {};
          setLimsClinicianAndCaseOrigin(groupId);
          setHiddenCaseOriginName(groupName);
        }}
      />
      <input type="hidden" id="caseOriginName" {...register("caseOriginName")} />
    </>
  );
};

export default UserGroup;
