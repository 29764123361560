import classNames from "classnames";
import React from "react";

interface CheckboxGroupProps {
  id: string;
  label: string;
  options: string[];
  indented?: boolean;
  disabled?: boolean;
}

const CheckboxGroup = React.forwardRef<HTMLInputElement, CheckboxGroupProps>(
  ({ id, label, options, indented, disabled, ...props }, ref) => {
    return (
      <div
        role="group"
        aria-labelledby={`${id}Label`}
        className={classNames("field mb-5", { indented, disabled })}
      >
        <h4 id={`${id}Label`} className="label is-size-6 has-text-weight-bold">
          {label}
        </h4>
        <div className="control">
          {options.map((option) => {
            return (
              <label
                key={option}
                className={classNames("checkbox", { "has-text-grey-light": disabled })}
              >
                <input
                  type="checkbox"
                  value={option}
                  disabled={disabled}
                  ref={ref}
                  {...props}
                />
                {option}
              </label>
            );
          })}
        </div>
      </div>
    );
  }
);

CheckboxGroup.displayName = "CheckboxGroup";

export default CheckboxGroup;
