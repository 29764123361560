import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PatientResearchStudy = (): JSX.Element => {
  const { register, formState, setValue } = useFormContext<CaseUiData>();

  return (
    <FormInput
      id="patientResearchStudy"
      label="Research study name"
      help="Optional (e.g. DEL)"
      error={formState.errors.patientResearchStudy}
      {...register("patientResearchStudy", {
        // Text field takes priority if overwriting BEST4 study arm choice
        onBlur: (e) => setValue("patientResearchStudy", e.target.value, setValueConfig),
        onChange: (e) => setValue("patientResearchStudy", e.target.value, setValueConfig),
      })}
    />
  );
};

export default PatientResearchStudy;
