import classNames from "classnames";
import React from "react";

import PasswordStrength from "../profile/PasswordStrength";
import usePasswordField from "./usePasswordField";

interface PasswordStrengthPanelProps {
  className?: string;
}

const PasswordStrengthPanel = ({
  className,
}: PasswordStrengthPanelProps): JSX.Element | null => {
  const password = usePasswordField();

  // Only render if the hook found a password input field
  if (password === null) return null;

  return (
    <div className={classNames("cy-password-strength-panel", className)}>
      <h2 className="title is-6">Password strength</h2>
      <PasswordStrength password={password} />
    </div>
  );
};

export default PasswordStrengthPanel;
