import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { FormMode } from "../FormWrapper";

export const TEST_ID_FORM_SAVE_CASE_BUTTON = "FormSaveCaseButton";

interface SubmitButtonProps {
  formMode: FormMode;
  formLocked: boolean;
}

const SubmitButton = ({
  formMode,
  formLocked,
}: SubmitButtonProps): JSX.Element | null => {
  if (formLocked) return null;
  return (
    <button
      type="submit"
      className="button is-primary is-large is-fullwidth"
      data-testid={TEST_ID_FORM_SAVE_CASE_BUTTON}
    >
      <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-3" />
      {formMode === "create" ? "Submit case" : "Save changes"}
    </button>
  );
};

export default SubmitButton;
