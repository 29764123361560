import React from "react";

import Fieldset from "../../components/forms/Fieldset";
import { FormSelectOption } from "../../components/forms/FormSelect";
import { DeviceId, LabNumber, RecordNumber } from "../fields/device";
import { LimsSpecimen } from "../fields/lims";

interface DeviceDetailsProps {
  allowUseRecordNumberForPatientName?: boolean;
  disabled: boolean;
  specimens: FormSelectOption[];
}

const DeviceDetails = ({
  allowUseRecordNumberForPatientName,
  disabled,
  specimens,
}: DeviceDetailsProps): JSX.Element => {
  return (
    <Fieldset title="Device details" className="tile is-child" disabled={disabled}>
      <div className="columns is-multiline">
        <div className="column is-full is-half-fullhd">
          <LabNumber />
        </div>
        <div className="column is-full is-half-fullhd">
          <RecordNumber
            allowUseRecordNumberForPatientName={allowUseRecordNumberForPatientName}
          />
        </div>
      </div>
      <DeviceId />
      <LimsSpecimen disabled={disabled} specimens={specimens} />
    </Fieldset>
  );
};

export default DeviceDetails;
