import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import FormSelect from "../../../components/forms/FormSelect";
import { getFormSelectOptionsFromArray } from "../../../helpers/strings";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface Best4StudyArmProps {
  disabled: boolean;
  studyArms: string[];
}

const Best4StudyArm = ({ disabled, studyArms }: Best4StudyArmProps): JSX.Element => {
  const { control, formState, setValue, watch } = useFormContext<CaseUiData>();
  const { patientResearchStudy } = watch();
  const options = getFormSelectOptionsFromArray(studyArms);

  useEffect(() => {
    // Clear non-BEST4 field value on component mount
    if (!studyArms.includes(patientResearchStudy)) {
      setValue("patientResearchStudy", "");
    }
  }, []);

  return (
    <FormSelect
      id="patientResearchStudy"
      label="Study arm"
      disabled={disabled}
      control={control}
      options={options}
      help="Select _repeat option if box was ticked"
      error={formState.errors.patientResearchStudy}
    />
  );
};

export default Best4StudyArm;
