import classNames from "classnames";
import { filter, find, includes, map } from "lodash";
import { useSnackbar } from "notistack";
import React, { FormEvent, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";

import { PortalUser } from "../../schemas/PortalUserSchema";
import {
  UserAccountOperationType,
  UserAccountTransition,
  UserType,
} from "../../schemas/UserSchema";
import { ApiResult, dataService } from "../../services/data.service";
import { AppDispatch } from "../../store";
import { updatePageNumber } from "../../store/userListSlice";

export const TEST_ID_DIALOG_MANAGE_PORTAL_USER_TITLE = "DialogManagePortalUserTitle";
export const TEST_ID_DIALOG_MANAGE_PORTAL_USER_CONFIRM_BUTTON =
  "DialogManagePortalUserConfirmButton";

interface PortalUserManageDialogProps {
  selectedUser: PortalUser;
  closeUserDialog: (e?: MouseEvent) => void;
}

interface PortalUserTransitionOption {
  operation: UserAccountTransition;
  label: string;
  description: string;
}

interface PortalUserAccountOperation extends PortalUserTransitionOption {
  request: (userId: string) => Promise<ApiResult<boolean>>;
  successMessage: string;
}

const PortalUserManageDialog = ({
  selectedUser: { userId, firstName, lastName, operations },
  closeUserDialog,
}: PortalUserManageDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedOperation, setSelectedOperation] = useState<UserAccountTransition>();

  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const { ACTIVATE, DEACTIVATE, DISABLE_MFA, DELETE } = UserAccountOperationType;
  const userDisplayName = `${firstName} ${lastName}`;
  const userOperations = map(operations, "operation");

  const managementOperations: PortalUserAccountOperation[] = [
    {
      operation: ACTIVATE,
      request: async () => await dataService.activatePortalUser(userId),
      label: `Reactivate account for ${userDisplayName}`,
      successMessage: `Reactivated account for ${userDisplayName}`,
      description: `Restore this user’s access to the customer portal. They will
                    be able to sign in. You can remove access by deactivating this
                    user again.`,
    },
    {
      operation: DEACTIVATE,
      request: async () => await dataService.deactivatePortalUser(userId),
      label: `Deactivate account for ${userDisplayName}`,
      successMessage: `Deactivated account for ${userDisplayName}`,
      description: `Remove this user’s access to the customer portal. They will
                    be unable to sign in. The user will not be deleted. You can
                    restore access by reactivating this user again.`,
    },
    {
      operation: DISABLE_MFA,
      request: async () => await dataService.disableMfaForPortalUser(userId),
      label: `Disable multi-factor authentication (MFA) for ${userDisplayName}`,
      successMessage: `MFA disabled for ${userDisplayName}`,
      description: `Turn off MFA for this user. If their group policy requires MFA, 
                    they will need to set it up again when they next sign in to the 
                    customer portal.`,
    },
    {
      operation: DELETE,
      request: async () => await dataService.deletePortalUser(userId),
      label: `Delete account for ${userDisplayName}`,
      successMessage: `Deleted account for ${userDisplayName}`,
      description: `Permanently delete this user’s account. This action cannot be
                    undone.`,
    },
  ];

  const allowedManagementOperations: PortalUserTransitionOption[] = filter(
    managementOperations,
    ({ operation }) => includes(userOperations, operation)
  );

  const handleClose = () => !busy && closeUserDialog();

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setError("");
    setBusy(true);
    const transition = find(managementOperations, ["operation", selectedOperation]);
    if (transition) {
      const response = await transition.request(userId);
      if (response.data) {
        // Return to the first page of results after deleting a user to prevent the
        // possibility of the list reload using a page number that no longer exists
        if (transition.operation === DELETE) {
          dispatch(updatePageNumber({ page: 1, listType: UserType.PORTAL }));
        }
        enqueueSnackbar(transition.successMessage, { variant: "success" });
        closeUserDialog();
      } else {
        setError(response.error?.msg ?? "Unexpected error. Please try again later.");
        setBusy(false);
      }
    }
  };

  const UserManagementOption = ({
    operation,
    label,
    description,
  }: PortalUserTransitionOption): JSX.Element => {
    return (
      <div className="columns is-mobile is-variable is-2" key={operation}>
        <div className="column is-narrow">
          <input
            type="radio"
            id={operation}
            value={operation}
            name="selectedOperation"
            checked={operation === selectedOperation}
            disabled={busy}
            onChange={(e) =>
              setSelectedOperation(e.target.value as UserAccountTransition)
            }
          />
        </div>
        <label htmlFor={operation} className="column is-clickable">
          <b className="is-block mb-1">{label}</b>
          {description}
        </label>
      </div>
    );
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box has-background-grey-lighter">
          <div className="content">
            <h3
              className="title is-4"
              data-testid={TEST_ID_DIALOG_MANAGE_PORTAL_USER_TITLE}
            >
              Manage portal user
            </h3>
            <form onSubmit={handleSubmit}>
              {allowedManagementOperations.map(({ operation, label, description }) => (
                <UserManagementOption
                  key={operation}
                  operation={operation}
                  label={label}
                  description={description}
                />
              ))}
              <div className="buttons">
                <button
                  type="submit"
                  disabled={!selectedOperation}
                  className={classNames("button is-primary", { "is-loading": busy })}
                  data-testid={TEST_ID_DIALOG_MANAGE_PORTAL_USER_CONFIRM_BUTTON}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  disabled={busy}
                  className="button is-light"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
              {!!error && <p className="notification is-danger">{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortalUserManageDialog;
