import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import { UserMfaStatus, userMfaStatusLabel } from "../../schemas/UserSchema";
import LabelledIcon from "../atoms/LabelledIcon";

interface UserMfaStatusIndicatorProps {
  mfaStatus: UserMfaStatus;
}

const UserMfaStatusIndicator = ({
  mfaStatus,
}: UserMfaStatusIndicatorProps): JSX.Element | null => {
  if (mfaStatus === UserMfaStatus.DISABLED) return null;
  return (
    <LabelledIcon
      icon={mfaStatus === UserMfaStatus.ENABLED ? faLock : faUnlock}
      label={userMfaStatusLabel[mfaStatus]}
      color={mfaStatus === UserMfaStatus.ENABLED ? "success" : "warning-dark"}
    />
  );
};

export default UserMfaStatusIndicator;
